import React from "react";
import IconWelcome from "../../../assets/images/ams/welcome.png";
import IconElephant from "../../../assets/images/ams/elephant.png";

export function Welcome() {
    return (
        <div className="welcome">
            <div className="card">
                <div className="row">
                    <div className="col-6">
                        <img src={IconWelcome} height={'240'}/>
                    </div>
                    <div className="col-6">
                        <img className="inconEl" src={IconElephant} height={'200'}/>
                    </div>
                    <div className="col-12">
                        <p className="fs-36 cl-orange-zp fw-bold mt-5">Cảm ơn bạn đã đăng ký tài khoản!</p>
                        <p className="mt-3">
                            <p className="cl-orange-zp fw-bold">Chào Luan,</p>
                            Bạn vừa tạo một tài khoản với email <span className="cl-orange-zp fw-bold">ABC@email.com</span>.
                            Chúng tôi rất vui khi bạn đã đăng ký sử dụng dịch vụ, để hoàn thành bước đăng ký và bắt đầu sử
                            dụng dịch vụ vui lòng bấm vào nút bên dưới để xác nhận tài khoản của bạn.
                        </p>
                    </div>
                    <div className="col-5">
                        <button className="btn w-100 btn bg-orange-bland-zp w-100 bdr-10 fs-16" type="button">Xác nhận đăng ký
                        </button>
                    </div>
                </div>
            </div>
            <div className="text-center">
                <p>Bạn nhận được email này vì bạn đã đăng ký sử dụng dịch vụ ở Voibay</p>
                <p>Bỏ theo dõi | Quy định | Chính sách sử dụng</p>
            </div>
        </div>
    )
}
