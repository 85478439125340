import React, { useEffect, useState } from "react";
import QSelect from "../../../../common/components/QSelect";
import ShipmentApi from "../../../../common/api/functions/Shipment";
import { ResponseInterface } from "../../../../interfaces/ResponseInterface";
import { ServiceInterface, ServiceShipmentInterface } from "../../../../interfaces/private/ServiceShipmentInterface";
import { config } from "../../../../config";
import { Formik, useFormik } from "formik";
import { SelectOptionInterface } from "../../../../interfaces/SelectOptionInterface";
import { QHelpers } from "../../../../common/helpers";
import { Button, Col, Row } from "reactstrap";
import OrderApi from "../../../../common/api/functions/Order";
import { OrderInterface } from "../../../../interfaces/private/OrderInterface";
import { useDispatch } from "react-redux";
import { setToast } from "../../../../app/rootReducer";
import QButton from "../../../../common/components/QButton";
import QModal from "../../../../common/components/QModal";
import QAlert from "../../../../common/components/Genneral/QAlert";
import QTextBox from "../../../../common/components/QTextBox";
import { ShipmentService } from "../../../../common/constant/OrderConstant";
import { useTranslation } from 'react-i18next';
import { ShipmentConstant } from "../../../../common/constant/ShipmentConstant";

interface Props {
    open: number | boolean, // open > 0 show
    order: OrderInterface | any,
    setOpen: Function,
    setLoading: Function,
    isMulti: boolean,
}

const BodyOpenComponent: React.FC<Props> = ({ setOpen, order, setLoading, open, isMulti, ...props }) => {
    const dispatch = useDispatch();
    const { t, i18n } = useTranslation();

    const [cLoading, setCLoading] = useState(true);
    const [messageErr, setMessageErr] = useState<any>("");

    const formik = useFormik({
        initialValues: {
            ids: []
        },
        onSubmit: async (values: any) => {
            try {
                let model: any = {
                    ids: [],
                }
                await OrderApi.openOrder({
                    ...model,
                    ids: open
                }).then(async (response: ResponseInterface | any) => {
                    if (response?.status === config.STATUS_CODE.OK && response?.data?.error === false) {
                        await setOpen(0);
                        await setLoading(true);
                        await dispatch(setToast({
                            message: t("order.openSuccess"),
                            type: config.TOAST_TYPE.SUCCESS,
                        }))
                    } else {
                        let content: any = response?.data?.data?.map((item: any) => {
                            return <p>{item?.data.message}</p>
                        })

                        setMessageErr(content);
                    }
                }, (response) => {
                    setMessageErr(response?.data?.message);
                })
            } catch (err: any) {
                await setMessageErr(err);
            }
        }
    })


    useEffect(() => {

        return () => {
            setMessageErr("");
        }
    }, [])

    return (
        <React.Fragment>

            <QModal
                open={open != 0}
                setOpen={() => {
                    setOpen(0)
                }}
                headerContent={t("order.confirm")}
                bodyContent={(
                    <React.Fragment>
                        <Row>
                        <p>{t("order.changeStatus.onHoldToOpen")}</p>

                        </Row>


                        <Row>
                            <Col>
                                <div className={'d-flex justify-content-end gap-2'}>
                                    <QButton
                                        type={'default'}
                                        onClick={() => { setMessageErr(""); setOpen(0) }}
                                        content={t('common.button.cancel')}
                                    />

                                    {
                                        order.shipment_id > 0 ? null : (
                                            <QButton
                                                type={'primary'}
                                                onClick={() => { formik.handleSubmit() }}
                                                content={t('common.button.submit')}
                                            />
                                        )
                                    }
                                </div>
                            </Col>
                        </Row>
                    </React.Fragment>
                )}
            />


        </React.Fragment>
    )
}

export default BodyOpenComponent
