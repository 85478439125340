import React, { useEffect, useState } from "react";
import { ResponseInterface } from "../../../interfaces/ResponseInterface";
import QLoading from "../../../common/components/QLoading";
import { UseExtLayout, UseSecond01Layout, UseSecondLayout } from "../../../context/LayoutContext";
import QModal from "../../../common/components/QModal";
import SetupApi from "../../../common/api/functions/Setup";
import { SetupInterface } from "../../../interfaces/private/SetupInterface";
import { config } from "../../../config";
import { QHelpers } from "../../../common/helpers";
import { useHistory } from "react-router-dom";
import { RouteDefine } from "../../../routes/RouteDefine";
import login from "../../../assets/images/login.png";
import createAccount from "../../../assets/images/create-account.png";
import economicToken from "../../../assets/images/economic-token.png";
import addTokenApp from "../../../assets/images/add-token-app.png";
import showEconomicToken from "../../../assets/images/show-economic-token.png";
import webhook from "../../../assets/images/webhook.png";
import webhookEconomic from "../../../assets/images/webhook-economic.png";
import webhookEconomicForm from "../../../assets/images/webhook-economic-form.png";
import shippingInformation from "../../../assets/images/shipping-information.png";
import successFirstLogin from "../../../assets/images/success-first-login.png";
import forgotPassword from "../../../assets/images/forgot-password.png";
import verifyEmail from "../../../assets/images/verify-email.png";
import verifyForgotPassword from "../../../assets/images/verify-forgot-password.png";
import forgotPasswordClient from "../../../assets/images/forgot-password-client.png";
import resetPassword from "../../../assets/images/reset-password.png";
import orders from "../../../assets/images/orders.png";
import openTab from "../../../assets/images/open-tab.png";
import onHoldTab from "../../../assets/images/on-hold-tab.png";
import unvailableTap from "../../../assets/images/unvailable-tap.png";
import onHoldSubmit from "../../../assets/images/on-hold-submit.png";
import onHoldTab2 from "../../../assets/images/on-hold-tab-2.png";
import changeOpenStatus from "../../../assets/images/change-open-status.png";
import shipButton from "../../../assets/images/ship-button.png";
import shipMethod from "../../../assets/images/ship-method.png";
import orderAfterShipped from "../../../assets/images/order-after-shipped.png";
import economicUpdate from "../../../assets/images/economic-update.png";
import logs from "../../../assets/images/logs.png";
import BusinessInfo from "../../../assets/images/business-info.png";
import changePassword from "../../../assets/images/change-password.png";
import changePassword2 from "../../../assets/images/change-password-2.png";
import economic from "../../../assets/images/economic.png";
import addApp from "../../../assets/images/add-app.png";
import generateToken from "../../../assets/images/generate-token.png";
import economicCopyAndPaste from "../../../assets/images/economic-copy-and-patse.png";
import webhookEconomicSetting from "../../../assets/images/webhook-economic-setting.png";
import webhookShortcuts from "../../../assets/images/webhook-shortcuts.png";
import shipment from "../../../assets/images/shipment.png";
import granteApp from "../../../assets/images/grante-app.png";
import editInfo from "../../../assets/images/edit-info.png";
import log from "../../../assets/images/log.png";
import logDetail from "../../../assets/images/log-detail.png";
import exportExcel from "../../../assets/images/export-excel.png";
import importExcel from "../../../assets/images/import-excel.png";
import integrateWoo from "../../../assets/images/integrate-woo.png";
import integrateTiki from "../../../assets/images/integrate-tiki.png";
import integrateLazada from "../../../assets/images/integrate-lazada.png";
import integrateShopee from "../../../assets/images/integrate-Shopee.png";
import loginOpenPlatformLazada from "../../../assets/images/login-open-platform-lazada.png";
import loginOpenPlatformShopee from "../../../assets/images/login-open-platform-shopee.png";
import sync from "../../../assets/images/sync.png";
import WooCreate from "../../../assets/images/Woo-create.png";
import WooAddKey from "../../../assets/images/Woo-add-key.png";
import WooGeneralKey from "../../../assets/images/Woo-general-key.png";
import WooKey from "../../../assets/images/Woo-key.png";
import WooPatsteKey from "../../../assets/images/Woo-patste-key.png";
import tikiLoginForm from "../../../assets/images/tiki-login-form.png";
import tikiCreateApp from "../../../assets/images/tiki-create-app.png";
import tikiSubmitApp from "../../../assets/images/tiki-submit-app.png";
import tikiViewDetail from "../../../assets/images/tiki-view-detail.png";
import tikiIntegrate from "../../../assets/images/tiki-integrate.png";
import tikiSellerInfo from "../../../assets/images/tiki-seller-info.png";
import tikiSubmitSeller from "../../../assets/images/tiki-submit-seller.png";
import tikiWarehouseInfo from "../../../assets/images/tiki-warehouse-info.png";
export function Document() {
    UseSecond01Layout();
    const history = useHistory();
    const [hashTag, setHashTag] = useState('');
    useEffect(() => {
        const url = new URL(window.location.href);
        if (url.hash) {
            setHashTag(url.hash);
        }
    }, [])

    useEffect(() => {
        if (hashTag) {
            let el: any = document.getElementById(hashTag?.replace('#', ''));
            if (el) {
                setTimeout(() => {
                    el.scrollIntoView({behavior: 'smooth'});
                }, 500)
            }
        }
    }, [hashTag])

    return (
        <div className={'row'}>
            <div className={"col-md-3 side-bar-document"}>
                <ul>
                    <li>
                        <a href="#create-new-account">1. Đăng nhập</a>
                    </li>
                    <li> <a href="#first-login">2. Tạo mới tài khoản </a>
                    </li>
                    <li> <a href="#forgot-password">3. Quên mật khẩu</a>
                    </li>
                    <li><a href="#shipping-management">4. Đơn hàng</a>
                    </li>
                    <li><a href="#setting">5. Thiết lập</a></li>
                    <li><a href="#shipped-information">6. Báo cáo vận chuyển</a></li>
                </ul>
            </div>
            <div className={"col-md-8"}>
                <section id="create-new-account">
                    <h1 className="text-danger text-center">Đồng bộ đơn hàng từ trang thương mại điện tử</h1>
                    <h4 className="text-center">Voibay sẽ hỗ trợ người dùng đồng bộ đơn hàng về hệ thống từ nhiều trang thương mại điện tử</h4>
                    <h5>1. Đăng nhập</h5>
                    <p>Nhập thông tin email và mật khẩu khi bạn đã có tài khoản</p>
                    <div className="text-center">
                        <a href="#"><img src={login} height={400} /></a>
                    </div>
                    <p className="text-center"><b>Đăng nhập thành công sẽ vào danh sách đơn hàng. </b></p>
                    <div className="text-center">
                        <a href="#"><img src={createAccount} width={1000} /></a>
                    </div>
                    <p className="text-center">Nếu bạn chưa có tại khoản thì chọn vào link Đăng ký.</p>
                </section>
                <section id="first-login">
                    <h5>2. Tạo mới tài khoản </h5>
                    <div className="text-center">
                        <a href="#"><img src={economicToken} height={500} /></a>
                    </div>
                    <p className="text-center">Nhấn vào link Đăng ký để tạo mới tài khoản.</p>
                    <div className="text-center">
                        <a href="#"><img src={addTokenApp} height={480} /></a>
                    </div>
                    <p className="text-center">Nhập thông tin và sau đó nhấn vào nút Tạo tài khoản.</p>
                    <div className="text-center">
                        <a href="#"><img src={showEconomicToken} height={400} /></a>
                    </div>
                    <p className="text-center">Hệ thống gửi mail xác nhận tới địa chỉ mail đăng ký tài khoản và bạn cần nhấn vào Xác nhận đăng ký từ mail để đăng nhập tài khoản mới.</p>
                </section>
                <section id="forgot-password">
                    <h5>3. Quên mật khẩu</h5>
                    <p>Nhấn vào link Quên mật khẩu để tạo lại mật khẩu.</p>
                    <div className="text-center">
                        <a href="#"><img src={forgotPassword} height={450} /></a>
                    </div>
                    <p className="text-center">Nhập địa chỉ email bạn muốn thay đổi mật khẩu.</p>
                    <div className="text-center">
                        <a href="#"><img src={forgotPasswordClient} height={400} /></a>
                    </div>
                    <p className="text-center">Bạn cần đăng nhập mail và chọn vào link tạo lại mật khẩu mới.</p>
                    <p className="text-center">Nhập mật khẩu mới.</p>
                    <div className="text-center">
                        <a href="#"><img src={verifyForgotPassword} height={400} /></a>
                    </div>
                    <p className="text-center">Sau khi nhập mật khẩu mới và nhấn Lưu, bạn có thể đăng nhập lại người dùng bằng mật khẩu mới.</p>
                </section>
                <section id="shipping-management">
                    <h5>4. Đơn hàng</h5>
                    <ul>
                        <li>
                            <p>Danh sách đơn hàng : Đơn đặt hàng được đồng bộ từ trang thương mại điện tử về hệ thống VoiBay.</p>
                            <div className="text-center">
                                <a href="#"><img src={orders} width={1000} /></a>
                            </div>
                        </li>
                        <li>
                            <p>Chờ xử lý : Đơn hàng được động bộ về được mặc định là Chờ xử lý.</p>
                            <div className="text-center">
                                <a href="#"><img src={openTab} width={1000} /></a>
                            </div>
                        </li>
                        <li>
                            <p>Tạm dừng:</p>
                            <div className="text-center">
                                <a href="#"><img src={onHoldTab} width={1000} /></a>
                            </div>
                            <p className="text-center">Xác nhận thông tin đơn hàng cần chuyển sang trạng thái Tạm dừng.</p>
                            <div className="text-center">
                                <a href="#"><img src={onHoldSubmit} width={1000} /></a>
                            </div>
                            <p className="text-center">Chuyển sang trạng thái Tạm dừng thành công</p>
                            <div className="text-center">
                                <a href="#"><img src={onHoldTab2} width={1000} /></a>
                            </div>
                            <p className="text-center">Bạn cũng có thể thay đổi trạng thái của đơn đặt hàng từ Tạm dừng sang Chờ xử lý.
                            </p>
                            <div className="text-center">
                                <a href="#"><img src={changeOpenStatus} width={1000} /></a>
                            </div>
                        </li>
                        <li>
                            <p>Hoàn thành :</p>
                            <p>Bạn có thể giao hàng bằng cách chọn nhiều đơn hàng cùng lúc và nhấn vào nút Giao hàng
                                Hoặc giao hàng từng đơn hàng bằng cách bấm vào biểu tượng   giao hàng trong danh sách đơn hàng Chờ xử lý
                            </p>
                            <div className="text-center">
                                <a href="#"><img src={shipButton} width={1000} /></a>
                            </div>
                            <p className="text-center">Sau khi chọn đơn hàng cần Giao hàng thì bạn cần chọn Phương thức vận chuyển.</p>
                            <div className="text-center">
                                <a href="#"><img src={shipMethod} width={1000} /></a>
                            </div>
                            <p className="text-center">Sau khi nhấn Lưu, hệ thống sẽ hiện thông tin vận chuyển với mã theo dõi đơn hàng,  bạn có thể tìm thông tin đơn hàng ở trang vận chuyển.</p>
                            <div className="text-center">
                                <a href="#"><img src={orderAfterShipped} width={1000} /></a>
                            </div>
                        </li>
                        <li>
                            <p>Nhật ký:</p>
                            <p>Hệ thống sẽ ghi lại tất cả các cập nhật và thay đổi theo thứ tự trong nhật ký.</p>
                            <div className="text-center">
                                <a href="#"><img src={log} width={1000} /></a>
                            </div>
                            <p></p>
                            <div className="text-center">
                                <a href="#"><img src={logDetail} width={1000} /></a>
                            </div>

                        </li>
                    </ul>
                </section>
                <section id="setting">
                    <h5>5. Thiết lập</h5>
                    <ul>
                        <li>
                            <p>Thông tin doanh nghiệp:</p>
                            <p>Thông tin người dùng sẽ được cập nhật trong Chi tiết doanh nghiệp</p>
                            <div className="text-center">
                                <a href="#"><img src={BusinessInfo} width={1000} /></a>
                            </div>
                            <p></p>
                            <div className="text-center">
                                <a href="#"><img src={editInfo} width={1000} /></a>
                            </div>
                            <p className="text-center">Thay đổi mật khẩu: Thay đổi mật khẩu khi đã đăng nhập</p>
                            <div className="text-center">
                                <a href="#"><img src={changePassword} width={1000} /></a>
                            </div>
                            <p className="text-center">Nhập mật khẩu hiện tại và mật khẩu cần thay đổi, và mật khẩu sẽ được thay đổi trong lần đăng nhập tiếp theo</p>
                            <div className="text-center">
                                <a href="#"><img src={changePassword2} width={1000} /></a>
                            </div>
                        </li>
                        <li>
                            <p>Kênh bán hàng:</p>
                            <p>Tạo tích hợp mới cho kênh bán hàng</p>
                            <div className="text-center">
                                <a href="#"><img src={economic} width={1000} /></a>
                            </div>
                            <p className="text-center">Chọn kênh bán hàng cần tạo tích hợp</p>
                            <div className="text-center">
                                <a href="#"><img src={addApp} width={1000} /></a>
                            </div>
                            <ul className="ml-4">
                                <li id="woo">
                                    <p>Woocommerce:</p>
                                    <div className="text-center">
                                        <a href="#"><img src={integrateWoo} width={800} /></a>
                                    </div>
                                    <p>Kênh bán hàng: nhập đường dẫn của trang bán hàng cần tạo tích hợp
                                        Key và secret : tạo key từ trang Woocommerce và nhập vào tích hợp.
                                    </p>
                                    <ul className="ml-4">
                                        <li>Step 1: Login Woocommerce</li>
                                        <li>
                                            <p>Step 2: Woocommerce {"=>"} Settings {"=>"} Advanced</p>
                                            <a href="#"><img src={WooCreate} width={800} /></a>
                                        </li>
                                        <li>
                                            <p>Step 3: REST API {"=>"} Add key</p>
                                            <a href="#"><img src={WooAddKey} width={800} /></a>
                                            <a href="#"><img src={WooGeneralKey} width={800} /></a>
                                            <p className="text-center">Nhập Mô tả ( Description) và chọn Đọc/Viết(Read/Write) và sau đó bấm nút Generate API key</p>
                                            <a href="#"><img src={WooKey} width={800} /></a>
                                            <p className="text-center">Sao chép(Copy)</p>
                                            <a href="#"><img src={WooPatsteKey} width={800} /></a>
                                            <p className="text-center">Và dán tương ứng giữa key và secret ở 2 trang</p>
                                        </li>
                                    </ul>
                                </li>
                                <li id="tiki">
                                    <p>Tiki</p>
                                    <ul className="ml-4">
                                        <li>
                                            <p>
                                                Step 1: Vào đường dẫn <a href="https://open.tiki.vn/web/account/login">https://open.tiki.vn/web/account/login</a>.
                                                Nhập thông tin người bán hàng để đăng nhập {"("}Mỗi người bán hàng có một mã riêng để tích hợp với hệ thống{")"}
                                            </p>
                                            <div className="text-center">
                                                <a href="#"><img src={tikiLoginForm} width={800} /></a>
                                            </div>
                                            <p className="text-center">Sau khi đăng nhập thành công bạn sẽ bấm vào nút Create</p>
                                            <div className="text-center">
                                                <a href="#"><img src={tikiCreateApp} width={800} /></a>
                                            </div>
                                            <div>
                                                <ol>1. Nhập thông tin bắt buộc *  và nhấn vào nút Submit</ol>
                                                <ol>2. Application name: Tên của để kết nối giữa Tiki vào ứng dụng VoiBay</ol>
                                                <ol>3. Summary: Tổng quan</ol>
                                                <ol>4. Description: Mô tả thông tin của ứng dụng</ol>
                                                <ol>5. Application homepage URL: Đường dẫn của trang bán hàng</ol>
                                                <ol>6. Application icon: taoh hình ảnh cho ứng dụng</ol>
                                                <ol>7. Application type: loại của ứng dụng là Inhouse</ol>
                                                <ol>8. Seller ID: số của mã của người bán hàng giống thông tin nhà bán hàng ở Tiki</ol>
                                                <ol>9. Seller name: Tên nhà bán hàng giống với tên nhà bán hàng ở Tiki</ol>
                                            </div>
                                            <div className="text-center">
                                                <a href="#"><img src={tikiSubmitApp} width={800} /></a>
                                            </div>
                                            <p  className="text-center">Sau khi xác nhận(Submit) hệ thống sẽ hiện mã ID và secret, bạn cần sao chép mã này và dán vào hiện thống Voibay</p>
                                        </li>
                                        <li>
                                            <p>
                                                Step 2 : Sao chép ( copy) mã ID và secret
                                            </p>
                                            <div className="text-center">
                                                <a href="#"><img src={tikiViewDetail} width={800} /></a>
                                            </div>
                                            <p  className="text-center">Dán(paste) mã ID và secret</p>
                                            <div className="text-center">
                                                <a href="#"><img src={tikiIntegrate} width={800} /></a>
                                            </div>
                                        </li>
                                        <li>
                                            <p>Step 3: Đồng thời bạn cũng cần vào người bán hàng của Tiki để xác nhận mã và secret mới tạo.</p>
                                            <div className="text-center">
                                                <a href="#"><img src={tikiSellerInfo} width={800} /></a>
                                            </div>
                                            <p className="text-center">Vào hồ sơ nhà bán</p>
                                            <div className="text-center">
                                                <a href="#"><img src={tikiSubmitSeller} width={800} /></a>
                                            </div>
                                            <p className="text-center">Đến thông kho hàng, mô hình vận chuyển và liên lạc</p>
                                            <div className="text-center">
                                                <a href="#"><img src={tikiWarehouseInfo} width={800} /></a>
                                            </div>
                                            <p className="text-center">Bấm vào nút Đồng ý thì mã ID và secret ở trên của bạn đã được kết nối Tiki và Voibay <br /> Và tích hợp giữa VoiBay và Tiki được tạo thành công.</p>
                                        </li>
                                    </ul>
                                </li>
                                <li id="lazada">
                                    <p>Lazada:</p>
                                    <p>Nhấn vào Tạo mã thông báo mới, hệ thống app fbzik sẽ  kết nối với hệ thống Lazada</p>
                                    <div className="text-center">
                                        <a href="#"><img src={integrateLazada} width={800} /></a>
                                    </div>
                                    <p className="text-center">Để xác nhận kết nối với hệ thống Lazada , cần nhập địa chỉ mail và mật khẩu của người bán hàng trên Lazada.</p>
                                    <div className="text-center">
                                        <a href="#"><img src={loginOpenPlatformLazada} width={800} /></a>
                                    </div>
                                    <p className="text-center">Sau đó lưu tích hợp với Lazada</p>
                                </li >
                                <li id="shopee">
                                    <p>Shopee:</p>
                                    <p>Nhấn vào Tạo mã thông báo mới, hệ thống app fbzik sẽ  kết nối với hệ thống Shopee</p>
                                    <div className="text-center">
                                        <a href="#"><img src={integrateShopee} width={800} /></a>
                                    </div>
                                    <p className="text-center">Để xác nhận kết nối với hệ thống Shopee , cần nhập địa chỉ mail và mật khẩu của người bán hàng trên Shopee.</p>
                                    <div className="text-center">
                                        <a href="#"><img src={loginOpenPlatformShopee} width={800} /></a>
                                    </div>
                                </li>
                                <li>
                                    <p>Đồng bộ:</p>
                                    <div className="text-center">
                                        <a href="#"><img src={sync} width={800} /></a>
                                    </div>
                                    <p className="text-center">Có thể đồng bộ một hoặc tất cả các kênh bán hàng. </p>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </section>
                <section id="shipped-information">
                    <h5>6. Báo cáo vận chuyển</h5>
                    <p>Thông tin đơn hàng đã được vận chuyển hoàn thành</p>
                    <div className="text-center">
                        <a href="#"><img src={shipment} width={1000} /></a>
                    </div>
                    <p className="text-center">Và có thể xem báo cáo bằng cách xuất trên excel</p>
                    <div className="text-center">
                        <a href="#"><img src={exportExcel} width={1000} /></a>
                    </div>
                    <div className="text-center">
                        <a href="#"><img src={importExcel} width={1000} /></a>
                    </div>
                </section>

            </div>
        </div>
    )
}

// @ts-ignore
export default React.memo(Document);
