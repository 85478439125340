import {config} from "../../config";
import {setAuthorization} from "../../common/api/base";
import {Redirect} from "react-router-dom";
import {RouteDefine} from "../../routes/RouteDefine";
import React from "react";

const AuthProtected = (props: any) => {
    let store: string|any = localStorage.getItem(config.LOCAL_STORE.ACCOUNT);
    store = JSON?.parse(store);
    setAuthorization(store?.access_token);

    if (!store?.access_token) {
        return (
            <Redirect to={{ pathname: RouteDefine.login, state: { from: props.location } }} />
        );
    }

    return <>{props.children}</>;
};

export default AuthProtected
