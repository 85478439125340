import React from 'react';
import {Redirect, Route, Switch} from "react-router-dom";

import {publicRoute} from "./publicRoute";
import {adminRoutes} from "./adminRoute";
import {authRoutes} from "./authRoute";
import {RouteInterface} from "../interfaces/RouteInterface";
import NonLayoutTemplate from "../layouts/NonLayout";
import {DashboardTemplate} from "../layouts/dashboard";
import {privateRoute} from "./privateRoute";
import AuthTemplate from "../layouts/Auth";
import WelcomeTemplate from "../layouts/Welcome";
import {RouteDefine} from "./RouteDefine";
import {welcomeRoute} from "./welcomeRoute";
import AdminTemplate from "../layouts/Admin";
import AuthProtected from "../layouts/components/AuthProtected";
import AccessRoute from "../layouts/components/AccessRoute";

const Routes = () => {
    const availablePublicRoutesPaths: Array<string> | any = publicRoute.map((r: RouteInterface) => r.path);
    const availableAuthRoutesPath: Array<string> | any = authRoutes.map((r: RouteInterface) => r.path);
    const availablePrivateRoute: Array<string> | any = privateRoute.map((r: RouteInterface) => r.path);
    const availableAdminRoute: Array<string> | any = adminRoutes.map((r: RouteInterface) => r.path);
    const availableWelcomeRoute: Array<string> | any = welcomeRoute.map((r: RouteInterface) => r.path);
    // const availablePrivateRoute01 : Array<string>|any = privateRoute01.map((r : RouteInterface) => r.path);
    // const availablePrivateRoute02 : Array<string>|any = privateRoute02.map((r : RouteInterface) => r.path);

    return (
        <React.Fragment>
            <Switch>
                <Route exact path="/">
                    <Redirect to={ RouteDefine.order } />
                </Route>

                <Route path={availablePublicRoutesPaths}>
                    <AuthTemplate>
                        <Switch>
                            {publicRoute.map((route: RouteInterface | any, idx) => {
                                return (
                                    <Route
                                        path={route.path}
                                        component={route.Component}
                                        key={idx}
                                        exact={true}
                                    />
                                )
                            })}
                        </Switch>
                    </AuthTemplate>
                </Route>
                <Route path={availableAuthRoutesPath}>
                    <AuthTemplate>
                        <Switch>
                            {authRoutes.map((route: RouteInterface | any, idx) => {
                                return (
                                    <Route
                                        path={route.path}
                                        component={route.Component}
                                        key={idx}
                                        exact={true}
                                    />
                                )
                            })}
                        </Switch>
                    </AuthTemplate>
                </Route>

                <Route path={availablePublicRoutesPaths}>
                    <NonLayoutTemplate>
                        <Switch>
                            {publicRoute.map((route: RouteInterface | any, idx) => {
                                return (
                                    <Route
                                        path={route.path}
                                        component={route.Component}
                                        key={idx}
                                        exact={true}
                                    />
                                )
                            })}
                        </Switch>
                    </NonLayoutTemplate>
                </Route>
                <Route path={availablePrivateRoute}>
                    <AuthProtected>
                        <DashboardTemplate>
                            <Switch>
                                {privateRoute.map((route: RouteInterface, idx: number) => (
                                    <AccessRoute
                                        path={route.path}
                                        component={route.Component}
                                        key={idx}
                                        exact={true}
                                    />
                                ))}
                            </Switch>
                        </DashboardTemplate>
                    </AuthProtected>
                </Route>
                <Route path={availableAdminRoute}>
                    <AuthProtected>
                        <AdminTemplate>
                            <Switch>
                                {adminRoutes.map((route: RouteInterface, idx: number) => (
                                    <AccessRoute
                                        path={route.path}
                                        component={route.Component}
                                        key={idx}
                                        exact={true}
                                    />
                                ))}
                            </Switch>
                        </AdminTemplate>
                    </AuthProtected>
                </Route>
                <Route path={availableWelcomeRoute}>
                    <WelcomeTemplate>
                        <Switch>
                            {welcomeRoute.map((route: RouteInterface | any, idx) => {
                                return (
                                    <Route
                                        path={route.path}
                                        component={route.Component}
                                        key={idx}
                                        exact={true}
                                    />
                                )
                            })}
                        </Switch>
                    </WelcomeTemplate>
                </Route>
            </Switch>
        </React.Fragment>
    );
};

export default Routes;
