export const RouteDefine = {
    resetPassword: '/reset-password',
    forgotPassword: '/forgot-password',

    setup: '/setup',
    login: '/login',
    order: '/orders',
    admin: '/admin',
    orderDetail: '/orders/:id',
    product: '/products',
    customer: '/customers',
    dashboard: '/dashboard',
    integrate: '/integrate',
    integrateNew: '/integrate/new',
    integrateDetail: '/integrate/:id',
    shipment: '/shipment',
    document: '/document',

    userUpdate: '/user',
    serviceManagement: '/service',
    userDetail: '/user/business-detail',
    business: '/business',
    businessUpdate: '/business-update',

    economic: '/economic',
    wooCommerce: '/woo-commerce',
    register: '/register',
    shippingInformation:'/shipping-information',
    welcome: '/welcome',
}
