export const GeneralConstant = {
    user: {
        role: {
            super_admin: 'super_admin',
            user: 'user',
        }
    },
    integrate: {
        tiki:3,
        woo:1,
        lazada:4,
        shopee:5

    }
}
