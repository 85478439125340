import React from "react";
import { withRouter } from 'react-router-dom';
import IconAmspace from "../../assets/images/logo/img.png";


function AuthTemplate({children} : any) {
    return (
        <>
            <div
                className="auth-page-wrapper auth-bg-cover-ams py-5 d-flex justify-content-center align-items-center min-vh-100">
                <div className="auth-page-content overflow-hidden">
                    <div className="container-detail">
                        <div className="overflow-hidden p-5 bdr-10 w-610">
                            <div className="row g-0">
                                <div className="col-lg-12">
                                    <div>
                                        <div className="d-flex">
                                            {/*         <div className="me-1">
                                                        <a href="#"><img src={IconLogo} /></a>
                                                    </div>*/}
                                            <div>
                                                <a href="#"><img src={IconAmspace} height={50} /></a>
                                            </div>
                                        </div>

                                        { children }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/*<footer className="footer">*/}
                {/*    <div className="container">*/}
                {/*        <div className="row">*/}
                {/*            <div className="col-lg-12">*/}
                {/*                <div className="text-center">*/}
                {/*                    <p className="mb-0 text-white cl-font-zp">&copy;*/}
                {/*                        2BS. Crafted with <i className="mdi mdi-heart text-danger"></i> by DragonflyTeam*/}
                {/*                    </p>*/}
                {/*                </div>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</footer>*/}
            </div>
        </>
    )
}

export default withRouter(AuthTemplate);
