import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { UseMainLayout } from "../../../context/LayoutContext";
import { PaginationInterface, structPagination } from "../../../interfaces/PaginationInterface";
import CustomerApi from "../../../common/api/functions/Customer";
import { ResponseInterface } from "../../../interfaces/ResponseInterface";
import { config } from "../../../config";
import BodyCustomerDetailComponent from "../Customer/components/BodyCustomerDetailComponent";
import QSearch from "../../../common/components/Genneral/QSearch";
import QLoading from "../../../common/components/QLoading";
import QPagination from "../../../common/components/QPaging";
import { RouteDefine } from "../../../routes/RouteDefine";
import { Link } from "react-router-dom";
import UserApi from "../../../common/api/functions/User";
import { UserInterface } from "../../../interfaces/private/UserInterface";
import BodyUserDetailComponent from "./components/BodyUserDetailComponent";
import DeleteIntegrateComponent from "../Integrate/components/DeleteIntegrateComponent";
import QModal from "../../../common/components/QModal";
import DeleteUserComponent from "./components/DeleteUserComponent";
import { structIntegration } from "../../../interfaces/private/IntegrateInterface";
import BodyUserCreateComponent from "./components/BodyUserCreateComponent";
import TextCollapse from "../../../common/components/TextCollapse/TextCollapse";
import { OrderConstant, UserConstant } from "../../../common/constant/OrderConstant";

export function ServiceManagement() {
    const { t }: any = useTranslation();
    const [users, setUsers] = useState<Array<UserInterface>>([]);
    const [user, setUser] = useState<UserInterface>();
    const [loading, setLoading] = useState(true);
    const [clickUserItem, setClickUserItem] = useState(0);
    const [updateClick, setUpdateClick] = useState(false);
    const [deleteClick, setDeleteClick] = useState(false);
    const [dataLog, setDataLog] = useState('');
    const [createNewClick, setCreateNewClick] = useState(false);
    const [pagination, setPagination] = useState<PaginationInterface | any>({
        ...structPagination,
        status: UserConstant.userStatus.all,
        key: 'id',
        sort: 'desc',
    })
    UseMainLayout();
    useEffect(() => {
        if (loading) {
            loadUser().then(async () => {
                await setLoading(false);
            });
        }
    }, [loading])

    const loadUser = async () => {
        try {
            await UserApi.getAll(pagination).then(async (response: ResponseInterface | any) => {
                if (response?.status === config.STATUS_CODE.OK) {
                    response = response?.data?.data;
                    await setUsers(
                        response?.data || []
                    )

                    await setPagination({
                        ...pagination,
                        page: response.current_page,
                        total: response.total,
                        per_page: response.per_page
                    })
                }
            })

        } catch (err) { }
        if (loading) {
            await setLoading(false);
        }
    }
    let roles = [
        { name: "Admin", id: 1 },
        { name: "Khách Hàng", id: 2 }
    ]
    let statusColors = [
        { class: "status-cancel", id: 1, name: 'Hủy bỏ' },
        { class: "status-accept", id: 2, name: 'Đồng ý' },
        { class: "status-processing", id: 3, name: 'Chờ xử lý' }
    ]
    const getFullName = (last_name: string, first_name: string) => {
        let FirstName = first_name === null ? '' : first_name;
        let LastName = last_name === null ? '' : last_name;
        return LastName + ' ' + FirstName;
    }
    const getRoleName = (role: number): any => {
        let roleChoose = roles.filter((e) => {
            return e.id === role;
        })
        return roleChoose[0].name;
    }
    return (
        <React.Fragment>
            <BodyUserDetailComponent
                model={user}
                open={updateClick}
                setOpen={async () => { await setUpdateClick(false) }}
                userId={clickUserItem}
                onFinishUpdate={loadUser}
            />
            <QModal
                open={deleteClick}
                setOpen={setUser}
                headerContent={t("integration.delete.title")}
                bodyContent={(
                    <DeleteUserComponent
                        model={user}
                        setModel={setUser}
                        setOpen={async () => { await setDeleteClick(false) }}
                        setLoadingFunc={setLoading}
                        SETdATALOG={
                            (data: any) => {
                                setDataLog(data);
                                // setOpenLog(true);
                            }
                        }
                        onFinishUpdate={loadUser}
                    />
                )}
            />

            <BodyUserCreateComponent
                model={user}
                open={createNewClick}
                setOpen={async () => { await setCreateNewClick(false) }}
                onFinishUpdate={loadUser}
            />
            <div className="col-md-12 relative">
                <div className="row">
                    <div className="col-md-2">
                        <div className="siderbar-nav-left mt-3 w-200">
                            <h3 className="fs-16 cl-ams fw-bold">{t("common.navbar.serviceManagement")}</h3>
                            <ul className="pl-0">
                                <li className={''}  >
                                    <Link to={""} >{t("common.navbar.user")}</Link>
                                </li>
                                <li className="" >
                                    <Link to={''}>{t("common.navbar.servicePackage")}</Link>
                                </li>
                                <li className="" >
                                    <Link to={''}>{t("common.navbar.registerPackage")}</Link>
                                </li>
                                <li className="" >
                                    <Link to={''}>{t("common.navbar.Team")}</Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-md-10">
                        <div className="col-12">
                            <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                <div className="page-title-name">{t("customer.customers")}</div>
                                <div className="page-title-right">
                                    <div className="d-flex">
                                        <a className="btn btn-sm  bg-orange-zp ml-3" onClick={() => {
                                            setCreateNewClick(true)
                                        }}>
                                            <span>
                                                <i className="mdi mdi-plus mr-2" />
                                                {t("Thêm khách hàng")}
                                            </span>
                                        </a>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <div className="mb-3">
                                <div className="card-header align-items-center d-flex">
                                    <div className="mb-0 flex-grow-1">
                                        <ul className="nav nav-tabs nav-tabs-custom nav-success bd-none" role="tablist">
                                            <li className="nav-item">
                                                {/* {
                                                    (pagination.status == OrderConstant.status.open) ? (<a className="btn btn-sm bdr-7 bg-transparent btn-border ml-3" onClick={() => {
                                                        setOnHoldClick(orderChecked.arrOrder)
                                                    }}>
                                                        <span> {t("order.status.on_hold")}</span>
                                                    </a>) : null
                                                } */}
                                            </li>
                                            <li className="nav-item">
                                                <a className="nav-link All py-3 pl-0" data-bs-toggle="tab" id="All" href="#All"
                                                    role="tab" aria-selected="false">{t("Chờ xử li")}
                                                </a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="nav-link All py-3 pl-0" data-bs-toggle="tab" id="All" href="#All"
                                                    role="tab" aria-selected="false">{t("Hoàn thành")}
                                                </a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="nav-link All py-3 pl-0" data-bs-toggle="tab" id="All" href="#All"
                                                    role="tab" aria-selected="false">{t("Hủy bỏ")}
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                    <QSearch
                                        onChange={async (value: string) => {
                                            await setPagination({
                                                ...pagination,
                                                search: value,
                                            })
                                            await setLoading(true);
                                        }}
                                    />
                                </div>
                                <QLoading loading={loading} />
                                <table className="table align-middle table-nowrap mb-0 relative" id="customerTable">
                                    <thead className="table-light">
                                        <tr>
                                            <th scope="col" style={{ width: '50px' }}>
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" id="checkAll"
                                                        defaultValue="option" />
                                                </div>
                                            </th>
                                            <th scope="col">{t("customer.table.customerNo")}</th>
                                            <th scope="col">{t("customer.table.company")}</th>
                                            <th scope="col">{t("customer.table.name")}</th>
                                            <th scope="col">{t("customer.table.phone")}</th>
                                            <th scope="col">{t("customer.table.role")}</th>
                                            <th scope="col">{t("customer.table.deliveryAddress")}</th>
                                            <th scope="col">{t("customer.table.status")}</th>
                                            <th scope="col">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody className="list form-check-all">
                                        {users?.map((user: UserInterface, index: number) => {
                                            let objectStatus = statusColors.filter((el) => { return el.id === user.status });
                                            return (
                                                <tr key={index}>

                                                    <th scope="row">
                                                        <div className="form-check">
                                                            <input className="form-check-input" type="checkbox" name="chk_child"
                                                                defaultValue="option1" />
                                                        </div>
                                                    </th>
                                                    <td>
                                                        <a className={"cursor-pointer"} onClick={() => { setUser(user); setUpdateClick(true); setClickUserItem(user.id) }}>
                                                            {user.id}
                                                        </a>

                                                    </td>

                                                    <td>
                                                        <a className={"cursor-pointer"} onClick={() => { setUser(user); setUpdateClick(true); setClickUserItem(user.id) }} >{user.company_name}</a>
                                                    </td>
                                                    <td>
                                                        <a className={"cursor-pointer"} onClick={() => { setUser(user); setUpdateClick(true); setClickUserItem(user.id) }} >{getFullName(user.first_name, user.last_name)}</a>
                                                    </td>
                                                    <td>{user.phone} </td>
                                                    <td>{getRoleName(user.role)} </td>
                                                    <td><TextCollapse text={user.address + ' ' + user.zip + ' ' + user.city + ' ' + user.country} maxLength={15} /></td>
                                                    <td><div className={"text-center bolder rounded-2 " + objectStatus[0].class}>{objectStatus[0].name}</div></td>
                                                    <td className="text-end">
                                                        <a className={""} >
                                                            <i className="las la-pen fs-16 cl-br-ams" onClick={() => { setUser(user); setUpdateClick(true); setClickUserItem(user.id) }} />
                                                        </a>
                                                        <a className={""}>
                                                            <i className="ml-2 las la-times fs-16 cl-br-ams" onClick={async () => {
                                                                await setDeleteClick(true);
                                                                await setUser(user);
                                                            }} />
                                                        </a>
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                            </div>
                            <QPagination
                                pagination={pagination}
                                setPagination={setPagination}
                                setLoading={setLoading}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default React.memo(ServiceManagement);