import {APIClient} from "../base";
import {PaginationInterface} from "../../../interfaces/PaginationInterface";

const OrderApi = {
    getAll: async (pagination: PaginationInterface) => await APIClient.get('/order', pagination),
    getDetail: async (id: number|string|any) => await APIClient.get('/order/' + id, {}),
    postShipment: async (orderId: number, model: object|any) => await APIClient.create(`/order/${orderId}/shipping`, model),
    getShipment: async (pagination: PaginationInterface) => await APIClient.get('/order/shipment', pagination),
    getAllLog: async (orderId: number|any) => await APIClient.get(`/order/${orderId}/log`, {sort: "desc",key: "created_at"}),
    postShipmentAll: async (object: any) => await APIClient.create('/order/shipping-all', object),
    onHoldOrder: async (object: any) => await APIClient.create('/order/on-hold-order', object),
    openOrder: async (object: any) => await APIClient.create('/order/open-order', object),
    update: async (orderId: number, object: any) => await APIClient.update(`/order/${orderId}`, object),
    shipmentPdf: async (orderId: number) => await APIClient.get(`order/shipment-pdf/${orderId}`, {}),
}

export default OrderApi;
