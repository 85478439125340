import React from "react";
import QTextBox from "../../../../common/components/QTextBox";
import { ResponseInterface } from "../../../../interfaces/ResponseInterface";
import { config } from "../../../../config";
import { Col, Row } from "reactstrap";
import { Formik } from "formik";
import { BusinessInterface } from "../../../../interfaces/private/BusinessInterface";
import BusinessApi from "../../../../common/api/functions/Business";
import { setToast } from "../../../../app/rootReducer";
import { useDispatch } from "react-redux";
import QButton from "../../../../common/components/QButton";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import QSelect from "../../../../common/components/QSelect";
import { SelectOptionInterface } from "../../../../interfaces/SelectOptionInterface";
import { AddressInterface, DistrictInterface, ServiceShipmentInterface, WardInterface } from "../../../../interfaces/private/ServiceShipmentInterface";
import { QHelpers } from "../../../../common/helpers";

interface Props {
    model: BusinessInterface,
    setOpen: Function,
    setLoadingFunc: Function,
    setBusiness: Function,
}

const BodyIntegrateComponent: React.FC<Props> = ({ model, setOpen, setLoadingFunc, setBusiness }) => {
    const dispatch = useDispatch();
    const [province, setProvince] = useState<Array<AddressInterface>>([]);
    const [district, setDistrict] = useState<Array<DistrictInterface>>([]);
    const { t } = useTranslation();
    useEffect(() => {

        BusinessApi.getAddress().then(async (response: ResponseInterface | any) => {
            if (response.status === config.STATUS_CODE.OK) {
                let provinces = Array.isArray(response?.data?.data) ? response?.data?.data : [];
                await setProvince(provinces)
                await setLoadingFunc(false)
            }
        })

        return () => {
            setLoadingFunc(true);
        }
    }, [])
    const handleGetDistrict = (name: number | any) => {
        let _province = province.find((c: AddressInterface) => c.name == name);
        if (!_province) return [];
        return _province?.districts?.map((c: DistrictInterface) => ({ label: c.name, value: c.name }))
    }
    let languages = [
        { name: "Tiếng Việt", id: 1 },
        { name: "English", id: 2 }
    ]
    let roles = [
        { name: "ADMIN", id: 1 },
        { name: "KHÁCH HÀNG", id: 2 },
        { name: "NGƯỜI DÙNG", id: 3 },
        { name: "NHÂN VIÊN", id: 4 },
    ]
    let status = [
        { id: 1, name: 'Hủy bỏ' },
        { id: 2, name: 'Đồng ý' },
        { id: 3, name: 'Chờ xử lý' }];
    const getFullName = (last_name: string, first_name: string) => {
        let FirstName = first_name === null ? '' : first_name;
        let LastName = last_name === null ? '' : last_name;
        return LastName + ' ' + FirstName;
    }
    const getRoleName = (role: number): any => {
        let roleChoose = roles.filter((e) => {
            return e.id === role;
        })
        return roleChoose[0].name;
    }
    const handleGetWard = (provinceName: number | any, districtName: number | any) => {
        let _province = province.find((c: AddressInterface) => c.name == provinceName);
        if (!_province) return [];
        let _district = _province?.districts?.find((d: DistrictInterface) => d.name == districtName)
        if (!_district) return [];
        return _district.wards?.map((c: WardInterface) => ({ label: c.name, value: c.name }))
    }
    model.country = "Việt Nam";
    return (
        <React.Fragment>
            <Formik
                initialValues={{ ...model }}
                onSubmit={(values: any) => {
                    setLoadingFunc(true);
                    BusinessApi.update(values?.id, values).then(async (response: ResponseInterface | any) => {
                        if (response.status === config.STATUS_CODE.OK) {
                            await setOpen(false);
                            await setLoadingFunc(true);
                            await setBusiness(response?.data);
                            await setLoadingFunc(false);
                            await dispatch(setToast({
                                message: t("business.message.updateSuccess"),
                                type: config.TOAST_TYPE.SUCCESS
                            }))
                        }
                    })
                }}
            >
                {formik => {
                    return (
                        <React.Fragment>
                            <Row style={{ maxHeight: '75vh', overflowY: 'auto' }}>
                                <Col>
                                    <QTextBox label={t("Tên")} value={formik.values.first_name} onChange={(value: string) => {
                                        formik.setFieldValue('first_name', value)
                                    }} />
                                    <QTextBox label={t("Họ")} value={formik.values.last_name} onChange={(value: string) => {
                                        formik.setFieldValue('last_name', value)
                                    }} />
                                    <QTextBox
                                        label={t("business.detail.company_name")}
                                        value={formik.values.company_name}
                                        onChange={(value: string) => {
                                            formik.setFieldValue('company_name', value)
                                        }}
                                    />
                                    <QTextBox
                                        label={t("business.detail.cvrNo")}
                                        value={formik.values.cvr_no}
                                        onChange={(value: string) => {
                                            formik.setFieldValue('cvr_no', value)
                                        }}
                                    />
                                    <QSelect
                                        label={t("business.detail.city")}
                                        value={formik.values.city == '' ? { label: formik.values.city, value: '' } :
                                            { label: formik.values.city, value: formik.values.city }

                                        }
                                        options={
                                            province?.map((c: AddressInterface) => ({ label: c.name, value: c.name }))
                                        }
                                        onChange={(option: SelectOptionInterface) => {
                                            formik.setFieldValue('city', option.value)
                                            formik.setFieldValue('district', '')
                                        }}
                                    />
                                    <QSelect
                                        label={t("business.detail.district")}
                                        value={formik.values.district == '' ? { label: formik.values.district, value: '' } : { label: formik.values.district, value: formik.values.district }}
                                        options={
                                            handleGetDistrict(formik.values.city)
                                        }
                                        onChange={(option: SelectOptionInterface) => {
                                            formik.setFieldValue('district', option.value)
                                            formik.setFieldValue('ward', '')

                                        }}
                                    />
                                    <QSelect
                                        label={t("business.detail.ward")}
                                        value={formik.values.ward == '' ? { label: formik.values.ward, value: '' } : { label: formik.values.ward, value: formik.values.ward }}
                                        options={
                                            handleGetWard(formik.values.city, formik.values.district)
                                        }
                                        onChange={(option: SelectOptionInterface) => {
                                            formik.setFieldValue('ward', option.value)
                                        }}
                                    />
                                    <QTextBox
                                        label={t("business.detail.address")}
                                        value={formik.values.address}
                                        onChange={(value: string) => {
                                            formik.setFieldValue('address', value)
                                        }}
                                    />


                                    <QTextBox
                                        label={t("business.detail.zip")}
                                        value={formik.values.zip}
                                        onChange={(value: string) => {
                                            formik.setFieldValue('zip', value)
                                        }}
                                    />

                                    <QTextBox
                                        label={t("business.detail.country")}
                                        value={formik.values.country}
                                        onChange={(value: string) => {
                                            formik.setFieldValue('country', value)
                                        }}
                                    />
                                    <QTextBox
                                        label={t("business.detail.email")}
                                        value={formik.values.email}
                                        onChange={(value: string) => {
                                            formik.setFieldValue('email', value)
                                        }}
                                    />
                                    <QTextBox
                                        label={t("business.detail.phone")}
                                        value={formik.values.phone}
                                        onChange={(value: string) => {
                                            formik.setFieldValue('phone', value)
                                        }}
                                    />
                                    <QTextBox
                                        label={t("business.detail.website")}
                                        value={formik.values.website}
                                        onChange={(value: string) => {
                                            formik.setFieldValue('website', value)
                                        }}
                                    />
                                    <QTextBox
                                        label={t("Tiền tệ")}
                                        value={formik.values.currency}
                                        onChange={(value: string) => {
                                            formik.setFieldValue('currency', value)
                                        }}
                                    />
                                    <QSelect
                                        label={t("Ngôn ngữ")}
                                        value={
                                            formik.values.language === undefined ? { label: languages[0].name, value: languages[0].id } : (
                                                QHelpers.object.mapSelectOption(
                                                    formik.values.language,
                                                    languages?.map((c: any) => ({ label: c.name, value: c.id }))
                                                )
                                            )
                                        }
                                        options={
                                            languages?.map((c: any) => ({ label: c.name, value: c.id }))
                                        }
                                        onChange={(option: SelectOptionInterface) => {
                                            formik.setFieldValue('language', option.value)
                                        }}
                                    />
                                    <QSelect
                                        label={t("Trạng thái")}
                                        value={
                                            formik.values.status === undefined ? { label: status[0].name, value: status[0].id } : (
                                                QHelpers.object.mapSelectOption(
                                                    formik.values.status,
                                                    status?.map((c: any) => ({ label: c.name, value: c.id }))
                                                )
                                            )
                                        }
                                        options={
                                            status?.map((c: any) => ({ label: c.name, value: c.id }))
                                        }
                                        onChange={(option: SelectOptionInterface) => {
                                            formik.setFieldValue('status', option.value)
                                        }}
                                    />
                                    <QSelect
                                        label={t("Vai trò")}
                                        value={
                                            formik.values.role === undefined ? { label: roles[0].name, value: roles[0].id } : (
                                                QHelpers.object.mapSelectOption(
                                                    formik.values.role,
                                                    roles?.map((c: any) => ({ label: c.name, value: c.id }))
                                                )
                                            )
                                        }
                                        options={
                                            roles?.map((c: any) => ({ label: c.name, value: c.id }))
                                        }
                                        onChange={(option: SelectOptionInterface) => {
                                            formik.setFieldValue('role', option.value)
                                        }}
                                    />
                                </Col>
                            </Row>

                            <Row>
                                <Col>
                                    <div className={'d-flex justify-content-end gap-2'}>

                                        <QButton
                                            type={'default'}
                                            onClick={() => { setOpen(0) }}
                                            content={t("common.button.cancel")}
                                        />

                                        <QButton
                                            type={'primary'}
                                            onClick={() => { formik.handleSubmit() }}
                                            content={t("common.button.submit")}
                                        />
                                    </div>
                                </Col>
                            </Row>
                        </React.Fragment>
                    )
                }}
            </Formik>
        </React.Fragment>
    )
}

export default BodyIntegrateComponent
