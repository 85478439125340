export interface UserInterface{
    "name": string|any,
    "first_name": string|any,
    "last_name": string|any,
    "email": string|any,
    "password": string|any,
    "company_name": string|any,
    "address": string|any,
    "zip": string|any,
    "city": string|any,
    "country": string|any,
    "phone": string|any,
    "website": string|any,
    "cvr_no": string|any,
    "id": string|any,
    "role": number|any,
    "status": number|any,
    'is_admin':boolean|any
}


export const structUser : UserInterface = {
    name:'',
    first_name:'',
    last_name:'',
    email:'',
    company_name:'',
    address:'',
    zip:'',
    city:'',
    country:'',
    phone:'',
    website:'',
    cvr_no:'',
    id:'',
    role: 2,
    status: 3,
    is_admin:false,
    password:''
}

