import React, { useState } from "react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ToastInterface } from "../../../interfaces/ToastInterface";
import { useDispatch } from "react-redux";
import { setToast } from "../../../app/rootReducer";
import {config} from "../../../config";
interface TextCollapseProps {
  text: string;
  maxLength: number;
}
const TextCollapse: React.FC<TextCollapseProps> = ({ text, maxLength }) => {
  const [isCollapsed, setIsCollapsed] = useState(true);

  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  const renderText = () => {
    if (text.length <= maxLength) {
      return text;
    }

    if (isCollapsed) {
      return `${text.slice(0, maxLength)}`;
    }

    return text;
  };

  return (
    <div className="d-flex">
      <p>{renderText()}</p>
      {text.length > maxLength && (
        <a onClick={toggleCollapse}>
          {isCollapsed ? '...' : '<-'}
        </a>
      )}
    </div>
  );
};

export default TextCollapse;