const status : any = {

    open: 1,
    on_hold: 2,
    shipped: 3,
    // booked: 4,
    // unavailable: 5,
    all: 0,
}
const userStatus : any = {

    cancel: 1,
    processing: 3,
    accept: 2,
    // booked: 4,
    // unavailable: 5,
    all: 0,
}

const statusTitle : any = {
    all: "All",
    open: "Open",
    on_hold: "On hold",
    shipped: "Shipped",
    booked: "Booked",
    // unavailable: "Unavailable",
}
const userStatusTitle : any = {
    cancel: "Hủy bỏ",
    processing: "Chờ xử lý",
    accept: "Chấp Nhận",
    all: "All",
}


export const OrderConstant = {
    status,
    statusTitle
}
export const UserConstant = {
    userStatus,
    userStatusTitle
}
export const ShipmentService = {
    deposit_service: '10',
    GTTK_service: '11'
}
