import React, { useEffect, useState } from "react";
import { Props } from "react-select";
import { RouteDefine } from "../../routes/RouteDefine";
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';

const Navbar: React.FC<Props> = () => {
    let urlPath = window.location.pathname;
    const [stateUrl, setStateUrl] = useState(window.location.pathname);
    const { t } = useTranslation();
    const [data, setData] = useState(Object);
    useEffect(() => {
        const storedData = localStorage.getItem('account');
        if (storedData !== null) {
            setData(JSON.parse(storedData));
        } else {
        }
    }, []);

    return <React.Fragment>
        <div className="app-menu navbar-menu">
            <div id="scrollbar">
                <div className="container-fluid">
                    <ul className="navbar-nav" id="navbar-nav">
                        {data.user?.is_admin ?
                            (
                                <li className="nav-item" onClick={() => { setStateUrl(RouteDefine.admin) }}>
                                    <Link className={"nav-link menu-link menu-link-ams " + (RouteDefine.admin === urlPath ? ' active' : '')} to={RouteDefine.admin} >{t("common.navbar.serviceManagement")}</Link>
                                </li>
                            ) :
                            (
                                <React.Fragment>
                                    <li className="nav-item" onClick={() => { setStateUrl(RouteDefine.order) }}>
                                        <Link className={"nav-link menu-link menu-link-ams " + ((RouteDefine.order === urlPath || RouteDefine.product === urlPath) ? ' active' : '')} to={RouteDefine.order}>
                                            <span data-key="t-home" className="fs-14">{t("common.navbar.shippingManagement")}</span>
                                        </Link>
                                        <div className="menu-dropdown p-0">
                                            <ul className="nav nav-sm flex-column">
                                                <li className={`nav-item ${stateUrl === RouteDefine.order ? ' active' : ''}`}
                                                    onClick={() => {
                                                        setStateUrl(RouteDefine.order)
                                                    }}
                                                >
                                                    <Link className={'nav-link fs-14 justify-content-center bd-bottom'} to={RouteDefine.order}>{t("order.orders")}</Link>
                                                </li>
                                                <li className={`nav-item  ${stateUrl === RouteDefine.product ? ' active' : ''}`}
                                                    onClick={() => {
                                                        setStateUrl(RouteDefine.product)
                                                    }}
                                                >
                                                    <Link className={'nav-link fs-14 justify-content-center bd-bottom'} to={RouteDefine.product}>{t("common.navbar.products")}</Link>
                                                </li>
                                                <li className={`nav-item ${stateUrl === RouteDefine.customer ? ' active' : ''}`}
                                                    onClick={() => {
                                                        setStateUrl(RouteDefine.customer)
                                                    }}
                                                >
                                                    <Link className={'nav-link fs-14 justify-content-center bd-bottom'} to={RouteDefine.customer}>{t("common.navbar.customers")}</Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </li>
                                    <li className="nav-item">
                                        <Link to={RouteDefine.business} className="nav-link menu-link menu-link-ams fs-14">{t("common.navbar.settings")}</Link>
                                    </li>

                                    <li className={"nav-item"}>
                                        <Link
                                            to={RouteDefine.shipment}
                                            className={"nav-link menu-link menu-link-ams fs-14" + (RouteDefine.shipment === urlPath ? ' active' : '')}
                                        >
                                            {t("common.navbar.shippedInformation")}
                                        </Link>
                                    </li>
                                </React.Fragment>
                            )
                        }
                        {/* end Dashboard Menu */}
                    </ul>
                </div>
                {/* Sidebar */}
            </div>
            <div className="sidebar-background" />
        </div>
    </React.Fragment>;
};
export default Navbar;
