import React, { useEffect, useRef, useState } from "react";
import QTextBox from "../../../../common/components/QTextBox";
import QDate from "../../../../common/components/QDate/QDate";
import QSelect from "../../../../common/components/QSelect";
import { CmsTypeInterface } from "../../../../interfaces/private/CmsTypeInterface";
import IntegrateApi from "../../../../common/api/functions/Integrate";
import { ResponseInterface } from "../../../../interfaces/ResponseInterface";
import { config } from "../../../../config";
import { Button, Col, Row } from "reactstrap";
import { Formik, useFormik } from "formik";
import { IntegrateInterface, structIntegration } from "../../../../interfaces/private/IntegrateInterface";
import { SelectOptionInterface } from "../../../../interfaces/SelectOptionInterface";
import { QHelpers } from "../../../../common/helpers";
import QButton from "../../../../common/components/QButton";
import { useDispatch } from "react-redux";
import { setToast } from "../../../../app/rootReducer";
import { GeneralConstant } from "../../../../common/constant/GeneralConstant";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { RouteDefine } from "../../../../routes/RouteDefine";

interface Props {
    model: IntegrateInterface | any,
    setOpen: Function,
    setLoadingFunc: Function
}

const BodyIntegrateComponent: React.FC<Props> = ({ model, setOpen, setLoadingFunc }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const history = useHistory();

    const [loading, setLoading] = useState(true);
    const [cmsType, setCmsType] = useState<Array<CmsTypeInterface>>([]);
    let cmsStatus = [
        {
            name: t("integration.active"),
            id: 1
        }, {
            name: t("integration.inactive"),
            id: 0
        }
    ]
    useEffect(() => {
        if (loading) {
            loadCmsType().then(async () => {
                await setLoading(false);
            });
        }
    }, [])


    const loadCmsType = async () => {
        try {
            await IntegrateApi.cmsType().then(async (response: ResponseInterface | any) => {
                if (response?.status === config.STATUS_CODE.OK) {
                    response = response?.data?.data;
                    setCmsType(
                        response || []
                    )
                }
            })

        } catch (err) {
        }
        if (loading) {
            await setLoading(false);
        }
    }

    const getDate = (date: string | any) => {
        if (!date) return '';

        if (typeof date === "string") {

            return new Date(date);
        }

        return date;
    }
    let objectParams = QHelpers.object.convertParamUrlToObject(window.location.search)

    const getlocalData = async (hyperlink: any, values: any) => {
        await localStorage.setItem(
            'model',
            JSON.stringify(values)
        )
        window.location.replace(hyperlink);
    }

    if (localStorage.getItem('model') != null) {
        let session: any = localStorage.getItem('model');
        session = JSON.parse(session);
        if (objectParams.token != null) {
            session.code = objectParams.token;
            if (session.cms_type == '4') {
                session.host = "https://api.lazada.vn/rest"
            }
        }
        if (objectParams.shopId != null) {
            session.shopee_shop_id = objectParams.shopId
            session.host = 'https://partner.shopeemobile.com'
        }
        model = { ...session };
    }

    return (
        <React.Fragment>
            <Formik
                initialValues={{ ...model }}
                onSubmit={(values: any) => {
                    values.order_last_update = QHelpers.date.formatDate(values.order_last_update);
                    if (values?.id > 0) {
                        IntegrateApi.update(values?.id, values).then(async (response: ResponseInterface | any) => {
                            if (response) {
                                await setOpen(false);
                                await setLoadingFunc(true);
                                await model({ ...structIntegration });
                                await dispatch(setToast({
                                    message: t("integration.updateSuccess"),
                                    type: config.TOAST_TYPE.SUCCESS
                                }))
                            }
                        }).catch((err) => {
                            let arrayErr = err?.data.message;
                            let messageErr = '';
                            for (const property in arrayErr) {
                                messageErr += arrayErr[property];
                            }
                            dispatch(setToast({
                                message: messageErr,
                                type: config.TOAST_TYPE.ERROR,
                            }))
                        });
                    } else {
                        IntegrateApi.create(values).then(async (response: ResponseInterface | any) => {
                            if (response) {
                                await setOpen(false);
                                // await model({ ...structIntegration });
                                await setLoadingFunc(true)
                                await dispatch(setToast({
                                    message: t("integration.createSuccess"),
                                    type: config.TOAST_TYPE.SUCCESS
                                }))
                            }
                        }).catch((err) => {
                            let arrayErr = err?.data.message;
                            let messageErr = '';
                            for (const property in arrayErr) {
                                messageErr += arrayErr[property];
                            }
                            dispatch(setToast({
                                message: messageErr,
                                type: config.TOAST_TYPE.ERROR,
                            }))
                        });
                    }
                }}
            >
                {formik => {
                    return (
                        <React.Fragment>
                            <Row>
                                <Col>
                                    <QSelect
                                        label={t("integration.detail.webShopName")}
                                        value={
                                            formik.values.cms_type == '' ? {
                                                label: 'Chọn kênh bán hàng',
                                                value: '',
                                            } : (QHelpers.object.mapSelectOption(
                                                formik.values.cms_type,
                                                cmsType.map((c: CmsTypeInterface) => {
                                                    return {
                                                        label: c.name,
                                                        value: c.id,
                                                    }
                                                })
                                            ))
                                        }
                                        options={cmsType.map((c: CmsTypeInterface) => {
                                            return {
                                                label: c.name,
                                                value: c.id,
                                            }
                                        })}
                                        onChange={(option: SelectOptionInterface) => {
                                            formik.setFieldValue('cms_type', option.value)
                                        }}
                                    />
                                    <QSelect
                                        label={t("integration.detail.status")}
                                        value={
                                            QHelpers.object.mapSelectOption(
                                                formik.values.status,
                                                cmsStatus.map((c: CmsTypeInterface) => {
                                                    return {
                                                        label: c.name,
                                                        value: c.id,
                                                    }
                                                })
                                            )
                                        }
                                        options={cmsStatus.map((c: CmsTypeInterface) => {
                                            return {
                                                label: c.name,
                                                value: c.id,
                                            }
                                        })}
                                        onChange={(option: SelectOptionInterface) => {
                                            formik.setFieldValue('status', option.value)
                                        }}
                                    />
                                    {/* <QTextBox
                                        label={'Platform'}
                                        value={''}
                                        onChange={() => { }}
                                    /> */}

                                    <QTextBox
                                        label={t("integration.detail.host")}
                                        placeholder={'https://diachikenhbanhang.com'}
                                        value={formik.values.host}
                                        onChange={(value: string) => {
                                            formik.setFieldValue('host', value)
                                        }}
                                    />
                                    {
                                        formik.values.cms_type == GeneralConstant.integrate.woo ? (
                                            <QTextBox
                                                label={'Key'}
                                                value={formik.values.key}
                                                onChange={(value: string) => {
                                                    formik.setFieldValue('key', value)
                                                }}
                                            />
                                        ) : null
                                    }
                                    {
                                        formik.values.cms_type == GeneralConstant.integrate.woo ? (
                                            <QTextBox
                                                type={t("integration.detail.password")}
                                                label={'Secret'}
                                                value={formik.values.secret}
                                                onChange={(value: string) => {
                                                    formik.setFieldValue('secret', value)
                                                }}
                                            />
                                        ) : null
                                    }

                                    {/* {
                                        formik.values.cms_type == GeneralConstant.integrate.tiki ? (
                                            formik.values.key = "7176174447549338",
                                            <QTextBox
                                                label={'Key'}
                                                value={formik.values.key}
                                                onChange={(value: string) => {
                                                    formik.setFieldValue('key', value)
                                                }}
                                            />
                                        ) : null
                                    }
                                    {
                                        formik.values.cms_type == GeneralConstant.integrate.tiki ? (
                                            formik.values.secret = "IEA7hpm44NEaWHEGJ6XWW77dW3sFKnIO",
                                            <QTextBox
                                                isVisible={formik.values.cms_type == GeneralConstant.integrate.tiki}
                                                type={t("integration.detail.password")}
                                                label={'Secret'}
                                                value={formik.values.secret}
                                                onChange={(value: string) => {
                                                    formik.setFieldValue('secret', value)
                                                }}
                                            />
                                        ) : null
                                    } */}
                                    {/* Tiki */}
                                    {
                                        formik.values.cms_type == GeneralConstant.integrate.tiki ? (
                                            <QTextBox
                                                // isVisible={formik.values.cms_type != GeneralConstant.integrate.tiki}
                                                label={'Key'}
                                                value={formik.values.key}
                                                onChange={(value: string) => {
                                                    formik.setFieldValue('key', value)
                                                }}
                                            />
                                        ) : null
                                    }
                                    {
                                        formik.values.cms_type == GeneralConstant.integrate.tiki ? (
                                            // formik.values.secret = "IEA7hpm44NEaWHEGJ6XWW77dW3sFKnIO",
                                            <QTextBox
                                                // isVisible={formik.values.cms_type != GeneralConstant.integrate.tiki}
                                                type={t("integration.detail.password")}
                                                label={'Secret'}
                                                value={formik.values.secret}
                                                onChange={(value: string) => {
                                                    formik.setFieldValue('secret', value)
                                                }}
                                            />
                                        ) : null
                                    }
                                    <QTextBox
                                        isVisible={formik.values.cms_type == GeneralConstant.integrate.tiki}
                                        label={t("integration.detail.accessToken")}
                                        value={formik.values.token}
                                        onChange={(value: string) => {
                                            formik.setFieldValue('token', value)
                                        }}
                                    />
                                    {
                                        formik.values.cms_type == GeneralConstant.integrate.tiki ? (<QButton onClick={async () => {
                                            try {
                                                await IntegrateApi.tikiToken(formik.values.key, formik.values.secret).then(async (response: ResponseInterface | any) => {
                                                    if (response?.status === config.STATUS_CODE.OK) {
                                                        response = response?.data?.data;
                                                        if (response != undefined) {
                                                            formik.setFieldValue('token', response.access_token)
                                                            formik.setFieldValue('host', 'https://api.tiki.vn/')
                                                        }
                                                        await dispatch(setToast({
                                                            message: t("integration.getTokenSuccess"),
                                                            type: config.TOAST_TYPE.SUCCESS,
                                                        }))
                                                    }
                                                })

                                            } catch (err) {
                                                await dispatch(setToast({
                                                    message: t("integrate.clientIdWrong"),
                                                    type: config.TOAST_TYPE.ERROR,
                                                }));

                                            }
                                            if (loading) {
                                                await setLoading(false);
                                            }
                                        }} content={t("integration.generateNewToken")}></QButton>) : null
                                    }
                                    {/* End Tiki */}
                                    {/* Lazada */}
                                    {
                                        formik.values.cms_type == GeneralConstant.integrate.lazada ? (
                                            formik.values.key = "109120",
                                            <QTextBox
                                                isVisible={formik.values.cms_type != GeneralConstant.integrate.lazada}
                                                label={'Key'}
                                                value={formik.values.key}
                                                onChange={(value: string) => {
                                                    formik.setFieldValue('key', value)
                                                }}
                                            />
                                        ) : null
                                    }
                                    {
                                        formik.values.cms_type == GeneralConstant.integrate.lazada ? (
                                            formik.values.secret = "reXjgELR6UMUnJtPBApjkhGDAxCo9ADh",
                                            <QTextBox
                                                isVisible={formik.values.cms_type != GeneralConstant.integrate.lazada}
                                                type={t("integration.detail.password")}
                                                label={'Secret'}
                                                value={formik.values.secret}
                                                onChange={(value: string) => {
                                                    formik.setFieldValue('secret', value)
                                                }}
                                            />
                                        ) : null
                                    }
                                    <QTextBox
                                        isVisible={formik.values.cms_type == GeneralConstant.integrate.lazada}
                                        label={'Code'}
                                        value={formik.values.code}
                                        onChange={(value: string) => {
                                            formik.setFieldValue('code', value)
                                        }}
                                    />
                                    {

                                        formik.values.cms_type == GeneralConstant.integrate.lazada ? (<QButton onClick={async () => {
                                            formik.setFieldValue('host', 'https://api.lazada.vn/rest');

                                            let hyperlink = QHelpers.string.generateUrlLazada(config.INTEGRATION.LAZADA.REDIRECT_URL, formik.values.key);
                                            getlocalData(hyperlink, formik.values)
                                        }}
                                            content={t("integration.generateNewToken")}></QButton>) : null
                                    }
                                    {/* End Lazada */}
                                    {/* Shopee */}
                                    {
                                        formik.values.cms_type == GeneralConstant.integrate.shopee ? (
                                            formik.values.key = "2004858",
                                            <QTextBox
                                                isVisible={formik.values.cms_type != GeneralConstant.integrate.shopee}
                                                label={'Key'}
                                                value={formik.values.key}
                                                onChange={(value: string) => {
                                                    formik.setFieldValue('key', value)
                                                }}
                                            />
                                        ) : null
                                    }
                                    {
                                        formik.values.cms_type == GeneralConstant.integrate.shopee ? (
                                            formik.values.secret = "58795747566f64624a675547524b626f4e4150416679704f66466a6168465562",
                                            <QTextBox
                                                isVisible={formik.values.cms_type != GeneralConstant.integrate.shopee}
                                                type={t("integration.detail.password")}
                                                label={'Secret'}
                                                value={formik.values.secret}
                                                onChange={(value: string) => {
                                                    formik.setFieldValue('secret', value)
                                                }}
                                            />
                                        ) : null
                                    }
                                    <QTextBox
                                        isVisible={formik.values.cms_type == GeneralConstant.integrate.shopee}
                                        label={'Code'}
                                        value={formik.values.code}
                                        onChange={(value: string) => {
                                            formik.setFieldValue('code', value)
                                        }}
                                    />
                                    {

                                        formik.values.cms_type == GeneralConstant.integrate.shopee ? (<QButton onClick={async () => {
                                            formik.setFieldValue('host', 'https://partner.shopeemobile.com');

                                            let hyperlink = QHelpers.string.generateUrlShopee(config.INTEGRATION.SHOPEE.REDIRECT_URL, formik.values.key, formik.values.secret);
                                            getlocalData(hyperlink, formik.values)
                                        }}
                                            content={t("integration.generateNewToken")}></QButton>) : null
                                    }
                                    {/* End Shopee */}
                                    {
                                        formik.values.cms_type == GeneralConstant.integrate.woo ? (<p className="pt-2">Hướng dẫn lấy key từ webshop:<a className="cl-orange-zp cursor-pointer" target={"_blank"} onClick={() => { history.push(RouteDefine.document + "#woo") }}>Link</a></p>) : null
                                    }
                                    {
                                        formik.values.cms_type == GeneralConstant.integrate.shopee ? (<p className="pt-2">Hướng dẫn lấy key từ webshop:<a className="cl-orange-zp cursor-pointer" target={"_blank"} onClick={() => { history.push(RouteDefine.document + "#shopee") }}>Link</a></p>) : null
                                    }
                                    {
                                        formik.values.cms_type == GeneralConstant.integrate.tiki ? (<p className="pt-2">Hướng dẫn lấy key từ webshop:<a className="cl-orange-zp cursor-pointer" onClick={() => { history.push(RouteDefine.document + "#tiki") }}>Link</a></p>) : null
                                    }
                                    {
                                        formik.values.cms_type == GeneralConstant.integrate.lazada ? (<p className="pt-2">Hướng dẫn lấy key từ webshop:<a className="cl-orange-zp cursor-pointer" onClick={() => { history.push(RouteDefine.document + "#lazada") }}>Link</a></p>) : null
                                    }
                                    <QTextBox
                                        isVisible={formik.values.cms_type == GeneralConstant.integrate.shopee}
                                        label={'Shop Id'}
                                        value={formik.values.shopee_shop_id}
                                        onChange={(value: string) => {
                                            formik.setFieldValue('shopee_shop_id', value)
                                        }}
                                    />
                                    <div className="d-flex">
                                        <QTextBox type={"checkbox"} value={formik.values.is_send_stock} check={formik.values.is_send_stock == 1 ? true : false} onChange={(value: boolean) => {
                                            if (formik.values.is_send_stock == true) {
                                                value = false;
                                                formik.setFieldValue('is_send_stock', false)
                                            } else {
                                                value = true;
                                                formik.setFieldValue('is_send_stock', true)
                                            }

                                        }} />
                                        <p className="ml-2">Is Sync</p>
                                    </div>
                                    <QDate
                                        label={t("integration.lastImport")}
                                        value={getDate(formik.values.order_last_update)}
                                        onChange={(date: Date | any) => {
                                            formik.setFieldValue('order_last_update', date);
                                        }}
                                    />
                                </Col>
                            </Row>

                            <Row>
                                <Col>
                                    <div className={'d-flex justify-content-end gap-2'}>
                                        <QButton
                                            type={'default'}
                                            onClick={async () => {
                                                await setOpen(false);
                                                localStorage.removeItem('model');
                                            }}
                                            content={t("common.button.cancel")}
                                        />

                                        <QButton
                                            type={'primary'}
                                            onClick={() => { formik.handleSubmit(); localStorage.removeItem('model'); }}
                                            content={t("common.button.submit")}
                                        />
                                    </div>
                                </Col>
                            </Row>
                        </React.Fragment>
                    )
                }}
            </Formik>
        </React.Fragment>
    )
}

export default React.memo(BodyIntegrateComponent)
