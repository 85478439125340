import React, {useState} from "react";
import QTextBox from "../../../common/components/QTextBox";
import {useFormik} from "formik";
import * as Yup from "yup";
import QLoading from "../../../common/components/QLoading";
import QButton from "../../../common/components/QButton";
import {ResponseInterface} from "../../../interfaces/ResponseInterface";
import {config} from "../../../config";
import {useHistory} from "react-router-dom";
import {RouteDefine} from "../../../routes/RouteDefine";
import {useDispatch} from "react-redux";
import RegisterApi from "../../../common/api/functions/Register";
import {setToast} from "../../../app/rootReducer";
import QModal from "../../../common/components/QModal";
import {useTranslation} from "react-i18next";

export function Register() {
    const { t } : any = useTranslation();
    const history = useHistory();
    const dispatch = useDispatch();

    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState('');

    const [loading, setLoading] = useState(false);

    const formik = useFormik({
        initialValues: {
            email: '',
            password: '',
            password_confirm: '',
            name: '',
            first_name:'',
            company_name: '',
            address: '',
            zip: '',
            city: '',
            country: '',
            phone: '',
            website: '',
            cvr_no: '',
        },
        validationSchema: Yup.object({
            email: Yup.string().required(t("auth.register.require.email")).email(),
            password: Yup.string().required(t("auth.register.require.password")).min(6,t("auth.register.min.password")),
            password_confirm: Yup.string()
                .oneOf([Yup.ref('password'), null], t("auth.register.repeat_not_match")),
            name: Yup.string().required(t("auth.register.require.name")),
        }),
        onSubmit: async (values, {setFieldError}) => {
            await setLoading(true);
            await RegisterApi.createAccount(values).then(async (response: ResponseInterface | any) => {
                if (response?.status === config.STATUS_CODE.OK) {
                    setMessage(response.data?.message);
                    await dispatch(setToast({
                        message: t("auth.register.create_success"),
                        type: config.TOAST_TYPE.SUCCESS,
                    }))
                    await setOpen(true);
                }
            }, (response) => {
                if(typeof response.data.message === "string"){
                    // setMessageErr(response?.data?.message);
                    dispatch(setToast({
                        message: response.data?.message,
                        type: config.TOAST_TYPE.ERROR,
                    }))
                }else{
                    let message = response.data.message;
                    for (let key in message){
                        setFieldError(key, message[key][0]);
                    }
                }
            }).catch(() => {
                dispatch(setToast({
                    message: t("auth.register.create_fail"),
                    type: config.TOAST_TYPE.ERROR,
                }))
            });


            await setLoading(false);
        }
    })

    return (
        <div>
            <div className="mt-4 position-relative">
                <QLoading loading={loading}/>
                <QModal
                    open={open}
                    setOpen={() => {
                        setOpen(false);
                        history.push(RouteDefine.login)
                    }}
                    loading={loading}
                    headerContent={t("auth.register.create_new_account")}
                    bodyContent={(
                        <React.Fragment>
                            {message}
                        </React.Fragment>
                    )}
                />
                <form action="" method="">
                    <h2 className="mb-4 mt-3  fs-32 fw-bold cl-orange-zp"> {t("auth.register.create_your_account")}</h2>
                    <div className="row">
                        <div className="col-6">
                            <QTextBox
                                label={t("auth.register.first_name")}
                                placeholder={''}
                                value={formik.values.first_name}
                                className={'form-control-zp bg-transparent bd-bottom'}
                                onChange={(value: string) => {
                                    formik.setFieldValue('first_name', value)
                                }}
                                error={formik.touched?.first_name && formik.errors?.first_name}
                            />
                        </div>
                        <div className="col-6">
                            <QTextBox
                                label={t("auth.register.name")}
                                placeholder={''}
                                value={formik.values.name}
                                className={'form-control-zp bg-transparent bd-bottom'}
                                onChange={(value: string) => {
                                    formik.setFieldValue('name', value)
                                }}
                                error={formik.touched?.name && formik.errors?.name}
                            />
                        </div>
                        <div className="col-12">
                            <QTextBox
                                label={t("auth.register.email")}
                                placeholder={t("auth.register.placeholder_mail")}
                                // className={'Enter username'}
                                value={formik.values.email}
                                className={'form-control-zp bg-transparent bd-bottom'}
                                onChange={(value: string) => {
                                    formik.setFieldValue('email', value)
                                }}
                                error={formik.touched?.email && formik.errors?.email}
                            />
                        </div>
                        <div className="col-12">
                            <QTextBox
                                label={'Phone'}
                                placeholder={t("auth.register.placeholder_phone")}
                                value={formik.values.phone}
                                className={'form-control-zp bg-transparent bd-bottom'}
                                onChange={(value: string) => {
                                    formik.setFieldValue('phone', value)
                                }}
                                error={formik.touched?.phone && formik.errors?.phone}
                            />
                        </div>
                        <div className="col-6">
                            <QTextBox
                                type={'password'}
                                label={t("auth.register.password")}
                                placeholder={t("auth.register.placeholder_pass")}
                                value={formik.values.password}
                                className={'form-control-zp bg-transparent bd-bottom'}
                                onChange={(value: string) => {
                                    formik.setFieldValue('password', value)
                                }}
                                error={formik.touched?.password && formik.errors?.password}
                            />
                        </div>
                        <div className="col-6">
                            <QTextBox
                                type={'password'}
                                label={t("auth.register.repeat_password")}
                                placeholder={t("auth.register.placeholder_pass")}
                                value={formik.values.password_confirm}
                                className={'form-control-zp bg-transparent bd-bottom'}
                                onChange={(value: string) => {
                                    formik.setFieldValue('password_confirm', value)
                                }}
                                error={formik.touched?.password_confirm && formik.errors?.password_confirm}
                            />
                        </div>
                    </div>

                    {/*<QTextBox
                        label={'Company Name'}
                        placeholder={''}
                        value={formik.values.company_name}
                        className={'form-control-zp bg-transparent bd-bottom'}
                        onChange={(value: string) => {
                            formik.setFieldValue('company_name', value)
                        }}
                        error={formik.touched?.company_name && formik.errors?.company_name}
                    />

                    <QTextBox
                        label={'Phone'}
                        placeholder={''}
                        value={formik.values.phone}
                        className={'form-control-zp bg-transparent bd-bottom'}
                        onChange={(value: string) => {
                            formik.setFieldValue('phone', value)
                        }}
                        error={formik.touched?.phone && formik.errors?.phone}
                    />


                    <QTextBox
                        label={'Address'}
                        placeholder={''}
                        value={formik.values.address}
                        className={'form-control-zp bg-transparent bd-bottom'}
                        onChange={(value: string) => {
                            formik.setFieldValue('address', value)
                        }}
                        error={formik.touched?.address && formik.errors?.address}
                    />
                    <QTextBox
                        label={'Zip'}
                        placeholder={''}
                        value={formik.values.zip}
                        className={'form-control-zp bg-transparent bd-bottom'}
                        onChange={(value: string) => {
                            formik.setFieldValue('zip', value)
                        }}
                        error={formik.touched?.zip && formik.errors?.zip}
                    />
                    <QTextBox
                        label={'City'}
                        placeholder={''}
                        value={formik.values.city}
                        className={'form-control-zp bg-transparent bd-bottom'}
                        onChange={(value: string) => {
                            formik.setFieldValue('city', value)
                        }}
                        error={formik.touched?.city && formik.errors?.city}
                    />
                    <QTextBox
                        label={'Country'}
                        placeholder={''}
                        value={formik.values.country}
                        className={'form-control-zp bg-transparent bd-bottom'}
                        onChange={(value: string) => {
                            formik.setFieldValue('country', value)
                        }}
                        error={formik.touched?.country && formik.errors?.country}
                    />
                    <QTextBox
                        label={'Website'}
                        placeholder={''}
                        value={formik.values.website}
                        className={'form-control-zp bg-transparent bd-bottom'}
                        onChange={(value: string) => {
                            formik.setFieldValue('website', value)
                        }}
                        error={formik.touched?.website && formik.errors?.website}
                    />
                    <QTextBox
                        label={'CVR No.'}
                        placeholder={''}
                        value={formik.values.cvr_no}
                        className={'form-control-zp bg-transparent bd-bottom'}
                        onChange={(value: string) => {
                            formik.setFieldValue('cvr_no', value)
                        }}
                        error={formik.touched?.cvr_no && formik.errors?.cvr_no}
                    />*/}


                    <div className="mt-4">
                        <QButton
                            content={t("auth.register.create_account")}
                            type={'success'}
                            width={'w-100'}
                            size={''}
                            className={'btn bg-orange-zp cl-bland-zp w-100 bdr-10 fs-16'}
                            onClick={() => {
                                formik.handleSubmit()
                            }}
                        />
                    </div>
                    <br/>
                    <br/>
                    <div className="mt-4">
                        <div className="form-check">
                            <input
                                className="form-check-input chk_agree"
                                type="checkbox"
                                name="chk_agree"
                                defaultValue=""
                            />
                            <label className="ml-2" >Tôi đồng ý với <a href={''} className="cl-orange-zp fw-bold text-decoration-underline">quy định sử dụng</a> và <a href={''} className="cl-orange-zp fw-bold text-decoration-underline">chính sách bảo mật</a> của Voibay</label>
                        </div>
                    </div>
                    <div>
                        <span>{t("auth.register.already_account")} </span>
                        <a className="cl-orange-zp fw-bold" href={'#'} onClick={()=>{history.push(RouteDefine.login)}}>
                          {t("auth.register.login")}
                        </a>
                    </div>

                </form>
            </div>
        </div>
    )
}
