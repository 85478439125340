import React, { useEffect, useState } from "react";
import { PaginationInterface, structPagination } from "../../../interfaces/PaginationInterface";
import { IntegrateInterface, structIntegration } from "../../../interfaces/private/IntegrateInterface";
import { ResponseInterface } from "../../../interfaces/ResponseInterface";
import QModal from "../../../common/components/QModal";
import QLoading from "../../../common/components/QLoading";
import QPagination from "../../../common/components/QPaging";
import IntegrateApi from "../../../common/api/functions/Integrate";
import BodyIntegrateComponent from "./components/BodyIntegrateComponent";
import { UseSecondLayout } from "../../../context/LayoutContext";
import Moment from "react-moment";
import { config } from "../../../config";
import SyncIntegrateComponent from "./components/SyncIntegrateComponent";
import ShowLogComponent from "./components/ShowLogComponent";
import { useTranslation } from "react-i18next";
import { QHelpers } from "../../../common/helpers";
import DeleteIntegrateComponent from "./components/DeleteIntegrateComponent";
import SyncAllIntegrateComponent from "./components/SyncAllIntegrateComponent";

export function Integrate() {
    const { t } = useTranslation();
    const [integrates, setIntegrates] = useState<Array<IntegrateInterface>>([]);
    const [sync, setSync] = useState(false);
    const [syncAll, setSyncAll] = useState(false);
    const [integrate, setIntegrate] = useState<IntegrateInterface | any>({ ...structIntegration });
    const [pagination, setPagination] = useState<PaginationInterface>({ ...structPagination })
    const [loading, setLoading] = useState(true);
    const [createNewClick, setCreateNewClick] = useState(false);
    const [dataLog, setDataLog] = useState('');
    const [openLog, setOpenLog] = useState(false);
    const [deleteIntegrate, setDeleteIntegrate] = useState(false);
    UseSecondLayout();
    useEffect(() => {
        if (loading) {
            loadIntegrate().then(async () => {
                await setLoading(false);
            });
        }
        let objectParams = QHelpers.object.convertParamUrlToObject(window.location.search)

        if (objectParams?.token != undefined && localStorage.getItem('model') != null) {
            setCreateNewClick(true);
        }

    }, [loading])
    const listCMSType = [
        { id: 1, name: "Woocomerce" },
        { id: 3, name: "Tiki" },
        { id: 4, name: "Lazada" },
        { id: 5, name: "Shopee" }
        
    ];

    const getNameIntegrate = (Id:number):any=>{
        let data = listCMSType.filter((index:any)=>{
            return index.id == Id;
        })

        return data[0]?.name;
    }
    const loadIntegrate = async () => {
        try {
            await IntegrateApi.list(pagination).then(async (response: ResponseInterface | any) => {
                if (response?.status === config.STATUS_CODE.OK) {
                    response = response?.data?.data;
                    setIntegrates(
                        response?.data || []
                    )

                    setPagination({
                        ...pagination,
                        page: response.current_page,
                        total: response.total,
                        per_page: response.per_page
                    })
                }
            })

        } catch (err) {
        }
        if (loading) {
            await setLoading(false);
        }
    }
    
    return (
        <React.Fragment>
            <QModal
                open={createNewClick}
                setOpen={async () => {
                    await setIntegrate({ ...structIntegration });
                    await setCreateNewClick(false)
                }}
                headerContent={t("integration.title")}
                bodyContent={(
                    <BodyIntegrateComponent
                        model={integrate}
                        setOpen={async () => { await setIntegrate({ ...structIntegration }); await setCreateNewClick(false) }}
                        setLoadingFunc={setLoading}
                    />
                )}
            />

            <QModal
                open={openLog}
                setOpen={setOpenLog}
                headerContent={t("integration.title")}
                bodyContent={(
                    <ShowLogComponent
                        model={openLog}
                        setOpen={async () => { await setOpenLog(false) }}
                        setLoadingFunc={setLoading}
                        dataLog={dataLog}
                    />
                )}
            />

            <QModal
                open={sync}
                setOpen={setSync}
                headerContent={t("integration.sync.title")}
                bodyContent={(
                    <SyncIntegrateComponent
                        integrate={integrate}
                        model={sync}
                        setOpen={async () => { await setSync(false) }}
                        setLoadingFunc={setLoading}
                        SETdATALOG={(data: any) => {
                            setDataLog(data);
                            setOpenLog(true);
                        }
                        }
                        onFinishSync={loadIntegrate}

                    />
                )}
            />
            <QModal
                open={syncAll}
                setOpen={setSyncAll}
                headerContent={t("integration.sync.title")}
                bodyContent={(
                    <SyncAllIntegrateComponent
                        model={syncAll}
                        setOpen={async () => { await setSyncAll(false) }}
                        setLoadingFunc={setLoading}
                        SETdATALOG={(data: any) => {
                            setDataLog(data);
                            setOpenLog(true);
                        }
                        }
                        onFinishSync={loadIntegrate}
                    />
                )}
            />
            <QModal
                open={deleteIntegrate}
                setOpen={setDeleteIntegrate}
                headerContent={t("integration.delete.title")}
                bodyContent={(
                    <DeleteIntegrateComponent
                        model={integrate}
                        setModel = {setIntegrate}
                        setOpen={async () => { await setDeleteIntegrate(false) }}
                        setLoadingFunc={setLoading}
                        SETdATALOG={
                            (data: any) => {
                                setDataLog(data);
                                // setOpenLog(true);
                            }
                        }
                        onFinishUpdate={loadIntegrate}
                    />
                )}
            />
            <div className="col-md-10 relative">
                <QLoading loading={loading} />
                <div className="row ">

                    <div className="col-12">
                        <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                            <div className="page-title-name">{t("integration.title")}</div>
                            <div className="page-title-right">
                                <div className="d-flex">
                                    <a className="btn btn-sm  bg-orange-zp ml-3" onClick={() => {
                                        setCreateNewClick(true)
                                    }}>
                                        <span>
                                            <i className="mdi mdi-plus mr-2" />
                                            {t("integration.create")}
                                        </span>
                                    </a>
                                    {
                                        integrates.length > 1?(<a className="btn btn-sm  bg-orange-zp ml-3" onClick={() => {
                                            setSyncAll(true)
                                        }}>
                                            <span>
                                                <i className=" las la-sync-alt mr-2" />
                                                {t("integration.syn-all")}
                                            </span>
                                        </a>):null
                                    }
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-12">
                        <div className="mb-3">
                            <table className="table align-middle table-nowrap mb-0 relative" id="customerTable">
                                <thead className="table-light">
                                    <tr>

                                        <th data-sort="customer" scope="col">{t("integration.host")}</th>
                                        <th data-sort="order_no" scope="col">{t("integration.platform")}</th>
                                        <th data-sort="channel" scope="col">{t("integration.status")}</th>
                                        <th data-sort="ordered" scope="col">{t("integration.lastImport")}</th>
                                        <th scope="col" />
                                    </tr>
                                </thead>
                                <tbody className="list form-check-all">
                                    {integrates?.map((integrate: IntegrateInterface, index: number) => {
                                        return (
                                            <tr key={index + 1}>
                                                <td>{integrate.host}</td>
                                                <td>{getNameIntegrate(integrate.cms_type)}</td>
                                                <td>{integrate.status == 1 ? t("integration.active") : t("integration.inactive")}</td>
                                                <td><Moment date={integrate.order_last_update} format="DD.MM.YY hh:mm" /></td>

                                                <td className="text-end">
                                                    <a className={"cursor-pointer"} onClick={async () => {
                                                        await setSync(true);
                                                        await setIntegrate(integrate)

                                                    }}>
                                                        <i className="mr-2 las la-sync-alt fs-16 cl-br-ams" />
                                                    </a>
                                                    <a className={"cursor-pointer"} onClick={async () => {
                                                        await setIntegrate(integrate)
                                                        await setCreateNewClick(true);
                                                    }}>
                                                        <i className="las la-pen fs-16 cl-br-ams" />
                                                    </a>
                                                    <a className={""} href="javascript:void (0)" onClick={async () => {
                                                        await setDeleteIntegrate(true);
                                                        await setIntegrate(integrate);
                                                    }}>
                                                        <i className="ml-2 las la-times fs-16 cl-br-ams" />
                                                    </a>
                                                </td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </div>
                        <QPagination
                            pagination={pagination}
                            setPagination={setPagination}
                            setLoading={setLoading}
                        />
                    </div>
                </div>
            </div>

        </React.Fragment>
    )
}

// @ts-ignore
export default React.memo(Integrate);
