import React, {useEffect, useState} from "react";
import {ResponseInterface} from "../../../interfaces/ResponseInterface";
import {config} from "../../../config";
import {PaginationInterface, structPagination} from "../../../interfaces/PaginationInterface";
import QPagination from "../../../common/components/QPaging";
import QLoading from "../../../common/components/QLoading";
import ProductApi from "../../../common/api/functions/Product";
import {ProductInterface} from "../../../interfaces/private/ProductInterface";
import BodyProductDetailComponent from "./components/BodyProductDetailComponent";
import {UseMainLayout} from "../../../context/LayoutContext";
import QSearch from "../../../common/components/Genneral/QSearch";
import moment from "moment";
import {useTranslation} from "react-i18next";
export function Product () {
    const {t}: any = useTranslation();
    const [products, setProducts] = useState <Array<ProductInterface>>([]);
    const [pagination, setPagination] = useState <PaginationInterface>({...structPagination})
    const [loading, setLoading] = useState(true);
    const [clickProductItem, setClickProductItem] = useState(0);
    UseMainLayout();
    useEffect(  () => {
        if(loading){
            loadProduct().then( async () => {
                await setLoading(false);
            });
        }
    }, [loading])

    const loadProduct = async () => {
        try {
            await ProductApi.getAll(pagination).then((response: ResponseInterface|any) => {
                if(response?.status === config.STATUS_CODE.OK)
                {
                    response = response?.data?.data;
                    setProducts(
                        response?.data || []
                    )

                    setPagination({
                        ...pagination,
                        page: response.current_page,
                        total: response.total,
                        per_page: response.per_page
                    })
                }
            })

        }catch (err){}
        if(loading){
            await setLoading(false);
        }
    }

    return (
        <React.Fragment>
            <BodyProductDetailComponent
                open={clickProductItem}
                setOpen={setClickProductItem}
            />
            <div className="col-md-12 relative">
                <div className="row ">
                    <div className="col-12">
                        <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                            <div className="page-title-name ">{t("product.products")}</div>
                            <div className="page-title-right">
                                <div className="d-flex">
                                    <a href="#" className="btn btn-sm  bg-orange-zp ml-3">
                                        <span>
                                            <i className="lab la-telegram mr-1" />
                                            {t("product.importViaIntegration")}
                                        </span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-12">
                        <div className="mb-3">
                            <div className="card-header align-items-center d-flex">
                                <div className="mb-0 flex-grow-1">
                                    <ul className="nav nav-tabs nav-tabs-custom nav-success bd-none" role="tablist">
                                        <li className="nav-item">
                                            <a className="nav-link All py-3 active pl-0" data-bs-toggle="tab" id="All" href="#All"
                                               role="tab" aria-selected="false">{t("product.all")}
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                                <QSearch
                                    onChange={ async (value: string) => {
                                        await setPagination({
                                            ...pagination,
                                            search: value,
                                        })
                                        await setLoading(true);
                                    }}
                                />
                            </div>
                            <QLoading loading={loading} />
                            <table className="table align-middle table-nowrap mb-0 relative" id="customerTable">
                                <thead className="table-light">
                                <tr>
                                    <th scope="col">{t("product.table.sku")}</th>
                                    <th scope="col">{t("product.table.name")}</th>
                                    <th scope="col">{t("product.table.weight")}</th>
                                    <th scope="col">{t("product.table.updated")}</th>

                                    {/*<th scope="col">updated</th>*/}
                                    {/*<th scope="col"></th>*/}
                                </tr>
                                </thead>
                                <tbody className="list form-check-all">
                                { products?.map((product: ProductInterface, index: number) => {
                                    return (
                                        <tr key={index}>

                                            <td>
                                                <a className={"cursor-pointer"} onClick={() => { setClickProductItem(product.id) }}>
                                                    { product.productNumber }
                                                </a>
                                            </td>

                                            <td>
                                                <a className={"cursor-pointer"} onClick={() => { setClickProductItem(product.id) }} >{ product.name }</a>
                                            </td>
                                            <td className="company_name">{ product.inventory_netWeight }</td>
                                            {/*<td>{ QHelpers.date.formatDate(new Date(product?.updated_at)) }</td>*/}
                                            <td>{(moment(product.updated_at)).isValid() && moment(product.updated_at).format('DD.MM.YY HH:mm')}</td>

                                            {/*<td className="text-end">
                                                <a className={""} href="order_detail.html">
                                                    <i className="las la-pen fs-16 cl-br-ams"/>
                                                </a>
                                                <a className={""} href="">
                                                    <i className="ml-2 las la-times fs-16 cl-br-ams"/>
                                                </a>
                                            </td>*/}
                                        </tr>
                                    )
                                }) }
                                </tbody>
                            </table>
                        </div>
                        <QPagination
                            pagination={pagination}
                            setPagination={setPagination}
                            setLoading={setLoading}

                        />
                        {/*<div className="noresult">*/}
                        {/*    <div className="text-center">*/}
                        {/*        /!*<lord-icon src="https://cdn.lordicon.com/msoeawqm.json" trigger="loop"*!/*/}
                        {/*        /!*           colors="primary:#121331,secondary:#08a88a"*!/*/}
                        {/*        /!*           style={{width: '75px', height: '75px'}}>*!/*/}
                        {/*        /!*</lord-icon>*!/*/}
                        {/*        <h5 className="mt-2">Sorry! No Result Found</h5>*/}
                        {/*        <p className="text-muted mb-0">We've searched more than 150+ contacts We did not find any*/}
                        {/*            contacts for you search.</p>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                    </div>
                </div>
            </div>

        </React.Fragment>
    )
}

export default Product;
