import React from "react";
import QTextBox from "../../../../common/components/QTextBox";
import { ResponseInterface } from "../../../../interfaces/ResponseInterface";
import { config } from "../../../../config";
import { Col, Row } from "reactstrap";
import { Formik } from "formik";
import QButton from "../../../../common/components/QButton";
import { useDispatch } from "react-redux";
import { setToast } from "../../../../app/rootReducer";
import SetupApi from "../../../../common/api/functions/Setup";
import { SetupInterface } from "../../../../interfaces/private/SetupInterface";
import { useHistory } from "react-router-dom";
import { QHelpers } from "../../../../common/helpers";
import { RouteDefine } from "../../../../routes/RouteDefine";
import { IntegrateInterface } from "../../../../interfaces/private/IntegrateInterface";
import IntegrateApi from "../../../../common/api/functions/Integrate";
import QDate from "../../../../common/components/QDate/QDate";

interface Props {
    model: IntegrateInterface,
    setOpen: Function,
    setLoadingFunc: Function,
    setSetup: Function,
}

const BodyEconomicComponent: React.FC<Props> = ({ model, setOpen, setLoadingFunc, setSetup }) => {
    const dispatch = useDispatch();
    const getDate = (date: string | any) => {
        if (!date) return '';

        if (typeof date === "string") {

            return new Date(date);
        }

        return date;
    }
    
    return (
        <React.Fragment>
            <Formik
                initialValues={{ ...model, cms_type: 6 }}
                onSubmit={(values: any) => {
                    IntegrateApi.updateByWooType(values).then(async (response: ResponseInterface | any) => {
                        if (response.status === config.STATUS_CODE.OK) {
                            await setOpen(false);
                            await setLoadingFunc(true);
                            await dispatch(setToast({
                                message: "Update success",
                                type: config.TOAST_TYPE.SUCCESS
                            }))
                        }
                    })
                }}
            >
                {formik => {

                    return (
                        <React.Fragment>
                            <Row>
                                <Col>
                                    <QTextBox
                                        label={'Host'}
                                        value={formik.values.host}
                                        onChange={(value: string) => {
                                            formik.setFieldValue('host', value)
                                        }}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <QTextBox
                                        label={'Consumer Key'}
                                        value={formik.values.key}
                                        onChange={(value: string) => {
                                            formik.setFieldValue('key', value)
                                        }}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <QTextBox
                                        label={'Consumer Secret'}
                                        value={formik.values.secret}
                                        onChange={(value: string) => {
                                            formik.setFieldValue('secret', value)
                                        }}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <QDate
                                    label={"Last Update"}
                                    value={getDate(formik.values.order_last_update)}
                                    onChange={(date: Date | any) => {
                                        formik.setFieldValue('order_last_update', date);
                                    }}
                                />
                            </Row>
                            <Row>
                                <Col>
                                    <div className={'d-flex justify-content-end gap-2'}>
                                        <QButton
                                            type={'default'}
                                            onClick={() => {
                                                setOpen(false)
                                            }}
                                            content={'Cancel'}
                                        />

                                        <QButton
                                            type={'primary'}
                                            onClick={() => {
                                                formik.handleSubmit()
                                            }}
                                            content={'Submit'}
                                        />

                                    </div>
                                </Col>
                            </Row>
                        </React.Fragment>
                    )
                }}
            </Formik>
        </React.Fragment>
    )
}

export default BodyEconomicComponent
