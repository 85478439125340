import React, { useEffect, useState } from "react";
import QTextBox from "../../../../common/components/QTextBox";
import QDate from "../../../../common/components/QDate/QDate";
import QSelect from "../../../../common/components/QSelect";
import { CmsTypeInterface } from "../../../../interfaces/private/CmsTypeInterface";
import IntegrateApi from "../../../../common/api/functions/Integrate";
import { ResponseInterface } from "../../../../interfaces/ResponseInterface";
import { config } from "../../../../config";
import { Button, Col, Row } from "reactstrap";
import { Formik } from "formik";
import { IntegrateInterface, structIntegration } from "../../../../interfaces/private/IntegrateInterface";
import { SelectOptionInterface } from "../../../../interfaces/SelectOptionInterface";
import { QHelpers } from "../../../../common/helpers";
import QButton from "../../../../common/components/QButton";
import { useDispatch } from "react-redux";
import { setToast } from "../../../../app/rootReducer";
import {useTranslation} from "react-i18next";

interface Props {
    model: IntegrateInterface | any,
    setOpen: Function,
    setLoadingFunc: Function,
    dataLog:any
}

const ShowLogComponent: React.FC<Props> = ({ model, setOpen, setLoadingFunc,dataLog }) => {
    const { t } = useTranslation();

    const dispatch = useDispatch();

    const [loading, setLoading] = useState(true);

    useEffect(() => {
    }, [loading])

    return (

        <React.Fragment>
            <Row>
                {
                    dataLog !==''?<div className="alert alert-warning"><p dangerouslySetInnerHTML={{__html:dataLog}}></p></div> : <p>{t("integration.sync.noThingTosync")}</p>
                }
            </Row>

            <Row>
                <Col>
                    <div className={'d-flex justify-content-end gap-2'}>
                        <QButton
                            type={'default'}
                            onClick={async () => {
                                await setOpen(false);
                            }}
                            content={t("integration.cancel")}
                        />
                    </div>
                </Col>
            </Row>
        </React.Fragment>
    )
}

export default ShowLogComponent
