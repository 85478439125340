import React, { useEffect, useState } from "react";
import QTextBox from "../../../../common/components/QTextBox";
import QDate from "../../../../common/components/QDate/QDate";
import QSelect from "../../../../common/components/QSelect";
import { CmsTypeInterface } from "../../../../interfaces/private/CmsTypeInterface";
import IntegrateApi from "../../../../common/api/functions/Integrate";
import { ResponseInterface } from "../../../../interfaces/ResponseInterface";
import { config } from "../../../../config";
import { Button, Col, Row } from "reactstrap";
import { Formik } from "formik";
import { IntegrateInterface, structIntegration } from "../../../../interfaces/private/IntegrateInterface";
import { SelectOptionInterface } from "../../../../interfaces/SelectOptionInterface";
import { QHelpers } from "../../../../common/helpers";
import QButton from "../../../../common/components/QButton";
import { useDispatch } from "react-redux";
import { setToast } from "../../../../app/rootReducer";
import QLoading from "../../../../common/components/QLoading";
import QModal from "../../../../common/components/QModal";
import ShowLogComponent from "./ShowLogComponent";
import { promises } from "stream";
import { resolve } from "path";
import { setTimeout } from "timers/promises";
import {useTranslation} from "react-i18next";

interface Props {
    model: IntegrateInterface | any,
    setOpen: Function,
    setLoadingFunc: Function,
    SETdATALOG: Function,
    onFinishSync:Function
}

const SyncAllIntegrateComponent: React.FC<Props> = ({ model, setOpen,SETdATALOG, setLoadingFunc, onFinishSync}) => {
    const { t } = useTranslation();

    const dispatch = useDispatch();
    const [openLog, setOpenLog] = useState(false);
    const [dataLog, setDataLog] = useState('');

    const [loading, setLoading] = useState(false);
    useEffect(() => {
        if (dataLog != '') {

        }
        
    }, [loading])

    return (
        <React.Fragment>
            <QModal
                open={openLog}
                setOpen={setOpenLog}
                headerContent={'Integration'}
                bodyContent={(
                    <ShowLogComponent
                        model={openLog}
                        setOpen={async () => { await setOpenLog(false) }}
                        setLoadingFunc={setLoading}
                        dataLog={dataLog}
                    />
                )}
            />
            <Formik
                initialValues={{ ...model }}
                onSubmit={() => {
                    setOpen(false);
                    dispatch(setToast({
                        message: t("integration.sync.wait")+"\nChúng tôi sẽ báo cho bạn biết khi hoàn thành.",
                        type: config.TOAST_TYPE.WARNING
                    }))
                    IntegrateApi.syncAllOrder().then(async (response: ResponseInterface | any) => {
                        if (response) {
                            onFinishSync();
                            await dispatch(setToast({
                                message: t("integration.syncSuccess"),
                                type: config.TOAST_TYPE.SUCCESS
                            }))

                            // await setLoadingFunc(true);
                            await setOpen(false);
                            await setOpenLog(true);
                            await setLoading(false);
                            SETdATALOG(response.data.message);
                            setDataLog(response.data.message);
                        }
                    })
                }}
            >
                {formik => {

                    return (
                        <React.Fragment>
                            <QLoading loading={loading} />
                            <Row>
                                <Col>
                                {t("integration.sync.all.confirm")}
                                </Col>
                            </Row>

                            <Row>
                                <Col>
                                    <div className={'d-flex justify-content-end gap-2'}>
                                        <QButton
                                            type={'default'}
                                            onClick={async () => {
                                                await setOpen(false);
                                            }}
                                            content={t("common.button.cancel")}
                                        />

                                        <QButton
                                            type={'primary'}
                                            onClick={async () => {
                                                formik.handleSubmit();
                                                await setLoading(true)
                                            }}
                                            content={t("integration.sync.title")}
                                        />

                                    </div>
                                </Col>
                            </Row>
                        </React.Fragment>
                    )
                }}
            </Formik>
        </React.Fragment>
    )
}

export default SyncAllIntegrateComponent
