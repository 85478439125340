import React from "react";
import IconShipment from "../../../../assets/images/ams/Truck icon.png";
// @ts-ignore
import { saveAs } from 'file-saver';
import { QHelpers } from "../../../../common/helpers";
import OrderApi from "../../../../common/api/functions/Order";
import {useTranslation} from "react-i18next";
import { config } from "../../../../config";

interface Props {
    trackAndTrace: string | any,
    label: string | any,
    companyShipId: number | any,
    orderId:number | any
}

const ShipmentNoComponent: React.FC<Props> = ({ trackAndTrace, label, companyShipId, orderId}) => {
    const { t } = useTranslation();
    return (
        <React.Fragment>
            <div className="bg-border-pd mb-3">
                <p className="fw-bold fs-20 mb-1">{t("order.orderDetail.shippingInformation")}</p>
                <div className="text-center">
                    <a><img src={IconShipment} /></a>
                    <p className="fw-bold fs-16 mb-1">{ t("order.orderDetail.trackAndTrace") }</p>
                    <a><span className="m"><i className="mdi mdi-eye-outline fs-16 me-2"></i></span><span
                        className="cl-orange-zp">
                        {
                            companyShipId == 1 ? <a className={"cl-orange-zp"} target={"_blank"} href={"https://5sao.ghn.dev/order/edit?type=edit&index=0&id=" + trackAndTrace}>
                                {trackAndTrace}
                            </a> : null
                        }
                        {
                            companyShipId == 10 ? <a className={"cl-orange-zp"} target={"_blank"} href={"https://viettelpost.vn/thong-tin-don-hang?peopleTracking=sender&orderNumber=" + trackAndTrace}>
                                {trackAndTrace}
                            </a> : null
                        }
                        {
                            companyShipId == 7 ? <a className={"cl-orange-zp"} target={"_blank"} href={"https://khachhang.giaohangtietkiem.vn/web/don-hang"}>
                                {trackAndTrace}
                            </a> : null
                        }
                    </span>
                    </a>
                    {
                        companyShipId == 7 ? <div className=" d-flex justify-content-center">
                            <div className="status-shipped mt-2 w-25"><a href={config.API_URL+"/shipment-pdf/"+orderId}>{t("order.orderDetail.printLabelPDF")}</a> </div>
                        </div> : null
                    }
                </div>
            </div>
        </React.Fragment>
    )
}

export default ShipmentNoComponent
