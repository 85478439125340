import React, { useEffect, useState } from "react";
import { ResponseInterface } from "../../../../interfaces/ResponseInterface";
import { config } from "../../../../config";
import { Formik, useFormik } from "formik";
import { QHelpers } from "../../../../common/helpers";
import { Button, Col, Row } from "reactstrap";
import OrderApi from "../../../../common/api/functions/Order";
import { OrderInterface } from "../../../../interfaces/private/OrderInterface";
import { useDispatch } from "react-redux";
import { setToast } from "../../../../app/rootReducer";
import QButton from "../../../../common/components/QButton";
import QModal from "../../../../common/components/QModal";
import QAlert from "../../../../common/components/Genneral/QAlert";
import QTextBox from "../../../../common/components/QTextBox";
import { ShipmentService } from "../../../../common/constant/OrderConstant";
import { useTranslation } from 'react-i18next';
import { ShipmentConstant } from "../../../../common/constant/ShipmentConstant";

interface Props {
    open: number | boolean, // open > 0 show
    order: OrderInterface | any,
    setOpen: Function,
    setLoading: Function,
    isMulti: boolean,
    onFinishUpdate:Function
}

const OrderDelivery: React.FC<Props> = ({ setOpen, order, setLoading, open, isMulti,onFinishUpdate, ...props }) => {
    const dispatch = useDispatch();
    const { t, i18n } = useTranslation();

    const [cLoading, setCLoading] = useState(false);
    useEffect(() => {
        if (order !== undefined) {
            formik.setFieldValue('delivery_address', order.delivery_address)
            formik.setFieldValue('delivery_ward', order.delivery_ward)
            formik.setFieldValue('delivery_district', order.delivery_district)
            formik.setFieldValue('delivery_city', order.delivery_city)
            formik.setFieldValue('delivery_country', order.delivery_country)
            formik.setFieldValue('delivery_zip', order.delivery_zip)
        }
    }, [order])

    const formik = useFormik({
        initialValues: {
            ...order
        },
        onSubmit: async (values: any) => {
            try {
                await OrderApi.update(order.id, values).then(async (response: ResponseInterface | any) => {
                    if (response?.status === config.STATUS_CODE.OK) {
                        onFinishUpdate();
                        await setOpen(0);
                        await setCLoading(false);
                        await dispatch(setToast({
                            message: t("order.shippingSuccess"),
                            type: config.TOAST_TYPE.SUCCESS,
                        }))
                    } else {
                        await dispatch(setToast({
                            message: "Update Order fail",
                            type: config.TOAST_TYPE.ERROR
                        }))
                    }
                }, (response) => {
                })
            } catch (err: any) {
            }
        }
    })

    return (
        <React.Fragment>
            <QModal
                open={open != 0}
                setOpen={() => {
                    setOpen(0)
                }}
                loading={cLoading}
                headerContent={'Địa chỉ nhận hàng'}
                bodyContent={(
                    <React.Fragment>
                        <Row>
                            <Col>
                                <QTextBox label={'Địa chỉ'} value={formik.values.delivery_address} onChange={(value: string) => {
                                    formik.setFieldValue('delivery_address', value)
                                }} />
                            </Col>

                            <Col>
                                <QTextBox label={'Phường'} value={formik.values.delivery_ward} onChange={(value: string) => {
                                    formik.setFieldValue('delivery_ward', value)
                                }} />
                            </Col>
                        </Row>

                        <Row>
                            <Col>
                                <QTextBox label={'Thành phố/Quận'} value={formik.values.delivery_district} onChange={(value: string) => {
                                    formik.setFieldValue('delivery_district', value)
                                }} />
                            </Col>

                            <Col>
                                <QTextBox label={'Tỉnh'} value={formik.values.delivery_city} onChange={(value: string) => {
                                    formik.setFieldValue('delivery_city', value)
                                }} />
                            </Col>
                        </Row>

                        <Row>
                            <Col>
                                <QTextBox label={'Quốc gia'} value={formik.values.delivery_country} onChange={(value: string) => {
                                    formik.setFieldValue('delivery_country', value)
                                }} />
                            </Col>

                            <Col>
                                <QTextBox label={'Zip'} value={formik.values.delivery_zip} onChange={(value: string) => {
                                    formik.setFieldValue('delivery_zip', value)
                                }} />
                            </Col>
                        </Row>

                        <Row>
                            <Col>
                                <div className={'d-flex justify-content-end gap-2'}>
                                    <QButton
                                        type={'default'}
                                        onClick={() => { setOpen(0) }}
                                        content={t('common.button.cancel')}
                                    />

                                    {
                                        <QButton
                                            type={'primary'}
                                            onClick={() => { formik.handleSubmit(); setCLoading(true) }}
                                            content={t('common.button.submit')}
                                        />
                                    }
                                </div>
                            </Col>
                        </Row>
                    </React.Fragment>
                )}
            />


        </React.Fragment>
    )
}

export default OrderDelivery
