import React, { useEffect, useState } from "react";
import QTextBox from "../../../common/components/QTextBox";
import { useFormik } from "formik";
import * as Yup from "yup";
import QLoading from "../../../common/components/QLoading";
import QButton from "../../../common/components/QButton";
import { ResponseInterface } from "../../../interfaces/ResponseInterface";
import { config } from "../../../config";
import { useHistory } from "react-router-dom";
import { RouteDefine } from "../../../routes/RouteDefine";
import { useDispatch } from "react-redux";
import { setToast } from "../../../app/rootReducer";
import { QHelpers } from "../../../common/helpers";
import UserApi from "../../../common/api/functions/User";
import {useTranslation} from "react-i18next";

export function ResetPassword() {
    const { t } : any = useTranslation();
    const history = useHistory();
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);

    let objectParams = QHelpers.object.convertParamUrlToObject(window.location.search);
    useEffect(() => {
        if (objectParams.verify === 1) {
            setOpen(true);
        }
    }, [objectParams.verify])


    const formik = useFormik({
        initialValues: {
            password: '',
            email: objectParams.email,
            sign: objectParams.sign,
            password_confirm: '',
            expire: objectParams.expire,
        },
        validationSchema: Yup.object({
            password: Yup.string().required(t("auth.reset.enter_password")),
            password_confirm: Yup.string().required(t("auth.reset.enter_password")),
        }),
        onSubmit: async (values) => {
            await setLoading(true);
            await UserApi.ResetPassword(values).then(async (response: ResponseInterface | any) => {
                
                if (response?.status === config.STATUS_CODE.OK) {
                    dispatch(setToast({
                        message: t("auth.reset.reset_password_success"),
                        type: config.TOAST_TYPE.SUCCESS,
                    }))
                    await localStorage.setItem(
                        config.LOCAL_STORE.ACCOUNT,
                        JSON.stringify(response.data.data),
                    )
                    await history.push(RouteDefine.login)
                }

            }).catch((err) => {
                let arrayErr = err?.data.message;
                let messageErr = '';
                for (const property in arrayErr) {
                    messageErr += arrayErr[property];
                }
                dispatch(setToast({
                    message: messageErr,
                    type: config.TOAST_TYPE.ERROR,
                }))
            });;
            await setLoading(false);
        }
    })

    return (
        <div>
            <div className="mt-4 position-relative">
                <QLoading loading={loading} />
                <form action="" method="">
                    <h2 className="mb-4 mt-3 fs-32 cl-orange-zp fw-400">{t("auth.reset.reset_password")}</h2>
                    <QTextBox
                        type={'password'}
                        label={t("auth.reset.password")}
                        placeholder={t("auth.reset.enter_password")}
                        value={formik.values.password}
                        className={'form-control-zp bg-transparent bd-bottom'}
                        onChange={(value: string) => {
                            formik.setFieldValue('password', value)
                        }}
                        error={formik.touched?.password && formik.errors?.password}
                    />
                    <QTextBox
                        type={'password'}
                        label={t("auth.reset.repeat_password")}
                        placeholder={t("auth.reset.enter_repeat_password")}
                        // className={'Enter username'}
                        value={formik.values.password_confirm}
                        className={'form-control-zp bg-transparent bd-bottom'}
                        onChange={(value: string) => {
                            formik.setFieldValue('password_confirm', value)
                        }}
                        error={formik.touched?.password_confirm && formik.errors?.password_confirm}
                    />
                    <div className="mt-4">
                        <QButton
                            content={t("common.button.submit")}
                            type={'success'}
                            width={'w-100'}
                            size={''}
                            className={'btn bg-orange-zp w-100 bdr-10 fs-16'}
                            onClick={() => {
                                formik.handleSubmit()
                            }}
                        />
                    </div>
                </form>
            </div>
        </div>
    )
}
export default ResetPassword
