const headers = (t : any) => {
    return [
        {
            label: "",
            checkbox: true,
        },
        {
            label: t("order.table.orderNo"),
            sort: 'orderNumber',
            className: "w-10p"
        },
        {
            label: t("order.table.customer"),
            className: "w-15p"
        },
        {
            label: t("order.table.orderDate"),
            sort: 'date',
            className: "w-10p"
        },
        {
            label: t("order.table.deliveryDate"),
            sort: 'delivery_deliveryDate',
            className: "w-10p"
        },
        {
            label: t("order.table.deliveryAddress"),
            className: "w-25p"
        },
        {
            label: t("order.table.weight"),
            className: "text-right w-10p"
        },
        {
            label: t("order.table.status"),
            className: "text-center"
        },
        {
            label: t("order.table.via"),
        },
        {
            label: t("order.table.service"),
        },
        {
            label: t("order.table.platform_name"),
        },
        {
            label: "",
        },
    ]
}

export const OrderConfig = {
    headers,
}
