import React from "react";
import Header from "../components/Header";
import Navbar from "../components/Navbar";
import { Link } from "react-router-dom";
import { RouteDefine } from "../../routes/RouteDefine";
import { useTranslation } from "react-i18next";

function AdminTemplate({ children }: HTMLElement | any) {
    const { t }: any = useTranslation();
    return (
        <React.Fragment>
            <div id="layout-wrapper">
                <Header />
                <Navbar />
                <div className="vertical-overlay" />
                <div className="main-content">
                    <div className="page-content">
                        <div className="container-fluid">
                            <div className="col-md-12 relative">
                                <div className="row">
                                    <div className="col-md-2">
                                        <div className="siderbar-nav-left mt-3 w-200">
                                            <h3 className="fs-16 cl-ams fw-bold">{t("common.navbar.serviceManagement")}</h3>
                                            <ul className="pl-0">
                                                <li className={''}  >
                                                    <Link to={RouteDefine.admin} >{t("common.navbar.user")}</Link>
                                                </li>
                                                <li className="" >
                                                    <Link to={''}>{t("common.navbar.servicePackage")}</Link>
                                                </li>
                                                <li className="" >
                                                    <Link to={''}>{t("common.navbar.registerPackage")}</Link>
                                                </li>
                                                <li className="" >
                                                    <Link to={''}>{t("common.navbar.Team")}</Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    {children}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default AdminTemplate;
