import React, { useEffect, useState } from "react";
import QTextBox from "../../../../common/components/QTextBox";
import { ResponseInterface } from "../../../../interfaces/ResponseInterface";
import { config } from "../../../../config";
import CustomerApi from "../../../../common/api/functions/Customer";
import { Col, Container, Row } from "reactstrap";
import QButton from "../../../../common/components/QButton";
import QModal from "../../../../common/components/QModal";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { setToast } from "../../../../app/rootReducer";
import { useTranslation } from 'react-i18next';
import BusinessApi from "../../../../common/api/functions/Business";
import { AddressInterface, DistrictInterface, WardInterface } from "../../../../interfaces/private/ServiceShipmentInterface";
import QSelect from "../../../../common/components/QSelect";
import { SelectOptionInterface } from "../../../../interfaces/SelectOptionInterface";
import { QHelpers } from "../../../../common/helpers";

interface Props {
    model: any, // open > 0 show
    setOpen: Function,
    open: any,
    customerId: any,
    onFinishUpdate: Function
}

const BodyCustomerDetailComponent: React.FC<Props> = ({ model, setOpen, open, customerId, onFinishUpdate }) => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const { t } = useTranslation();
    const [province, setProvince] = useState<Array<AddressInterface>>([]);

    useEffect(() => {
        if (model !== undefined) {
            formik.setFieldValue('customerNumber', model.customerNumber)
            formik.setFieldValue('name', model.name)
            formik.setFieldValue('email', model.email)
            formik.setFieldValue('zip', model.zip)
            formik.setFieldValue('address', model.address)
            formik.setFieldValue('currency', model.currency)
            formik.setFieldValue('city', model.city)
            formik.setFieldValue('mobilePhone', model.mobilePhone)
            formik.setFieldValue('country', 'Việt Nam')
            formik.setFieldValue('ward', model.ward)
            formik.setFieldValue('district', model.district)
        }
        BusinessApi.getAddress().then(async (response: ResponseInterface | any) => {
            if (response.status === config.STATUS_CODE.OK) {
                let provinces = Array.isArray(response?.data?.data) ? response?.data?.data : [];
                await setProvince(provinces)
            }
        })


    }, [model])
    let roles = [
        { name: "Admin", id: 1 },
        { name: "Khách hàng", id: 2 },
        { name: "Người dùng", id: 3 },
        { name: "Nhân viên", id: 4 },
    ]
    const formik = useFormik({
        initialValues: {
            ...model
        },
        onSubmit: async (values: any) => {
            try {
                await CustomerApi.update(customerId, values).then(async (response: ResponseInterface | any) => {
                    if (response?.status === config.STATUS_CODE.OK) {
                        onFinishUpdate();
                        await setOpen(0);
                        await setLoading(false);
                        await dispatch(setToast({
                            message: t("customer.update_success"),
                            type: config.TOAST_TYPE.SUCCESS,
                        }))
                    } else {
                        await dispatch(setToast({
                            message: t("customer.update_fail"),
                            type: config.TOAST_TYPE.ERROR
                        }))
                    }
                }, (response) => {
                })
            } catch (err: any) {
            }
        }
    })
    const handleGetDistrict = (name: number | any) => {
        let _province = province.find((c: AddressInterface) => c.name == name);
        if (!_province) return [];
        return _province?.districts?.map((c: DistrictInterface) => ({ label: c.name, value: c.name }))
    }
    const handleGetWard = (provinceName: number | any, districtName: number | any) => {
        let _province = province.find((c: AddressInterface) => c.name == provinceName);
        if (!_province) return [];
        let _district = _province?.districts?.find((d: DistrictInterface) => d.name == districtName)
        if (!_district) return [];
        return _district.wards?.map((c: WardInterface) => ({ label: c.name, value: c.name }))
    }

    return (
        <React.Fragment>
            <QModal
                loading={loading}
                open={open > 0}
                setOpen={() => { setOpen(false) }}
                headerContent={t("customer.detail.title")}
                className={'modal-md-zp'}
                bodyContent={(
                    <React.Fragment>
                        <Container>
                            <Row>
                                <Col>
                                    <QTextBox label={t("customer.detail.no")} value={formik.values.customerNumber} onChange={(value: string) => {
                                        formik.setFieldValue('customerNumber', value)
                                    }} />
                                </Col>
                                <Col>
                                    <QTextBox label={t("customer.detail.name")} value={formik.values.name} onChange={(value: string) => {
                                        formik.setFieldValue('name', value)
                                    }} />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <QTextBox label={t("customer.detail.email")} value={formik.values.email} onChange={(value: string) => {
                                        formik.setFieldValue('email', value)
                                    }} />
                                </Col>
                                <Col>
                                    <QTextBox label={t("customer.detail.postalCode")} value={formik.values.zip} onChange={(value: string) => {
                                        formik.setFieldValue('zip', value)
                                    }} />
                                </Col>

                            </Row>
                            <Row>
                                <Col>
                                    <QTextBox label={t("customer.detail.address")} value={formik.values.address} onChange={(value: string) => {
                                        formik.setFieldValue('address', value)
                                    }} />
                                </Col>
                                <Col>
                                    <QTextBox label={t("customer.detail.phone")} value={formik.values.mobilePhone} onChange={(value: string) => {
                                        formik.setFieldValue('mobilePhone', value)
                                    }} />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <QSelect
                                        label={t("customer.detail.city")}
                                        value={formik.values.city == '' ? { label: formik.values.city, value: '' } :
                                            { label: formik.values.city, value: formik.values.city }
                                        }
                                        options={
                                            province?.map((c: AddressInterface) => ({ label: c.name, value: c.name }))
                                        }
                                        onChange={(options: SelectOptionInterface) => {
                                            formik.setFieldValue('city', options.value);
                                            formik.setFieldValue('district', '')

                                        }} />
                                </Col>

                                <Col>
                                    <QSelect
                                        label={t("customer.detail.district")}
                                        value={formik.values.district == '' ? { label: formik.values.district, value: '' } : { label: formik.values.district, value: formik.values.district }}
                                        options={
                                            handleGetDistrict(formik.values.city)
                                        }
                                        onChange={(option: SelectOptionInterface) => {
                                            formik.setFieldValue('district', option.value)
                                            formik.setFieldValue('ward', '')

                                        }}
                                    />
                                </Col>
                                <Col>
                                    <QSelect
                                        label={t("customer.detail.ward")}
                                        value={formik.values.ward == '' ? { label: formik.values.ward, value: '' } : { label: formik.values.ward, value: formik.values.ward }}
                                        options={
                                            handleGetWard(formik.values.city, formik.values.district)
                                        }
                                        onChange={(option: SelectOptionInterface) => {
                                            formik.setFieldValue('ward', option.value)
                                        }} />
                                </Col>
                            </Row>

                            <Row>
                                <Col>
                                    <QTextBox label={t("customer.detail.currency")} value={"VND"} onChange={(value: string) => {
                                        formik.setFieldValue('currency', value)
                                    }} />
                                </Col>

                                <Col>
                                    <QTextBox label={t("customer.detail.country")} value={formik.values.country} onChange={(value: string) => {
                                        formik.setFieldValue('country', "Việt Nam")
                                    }} />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <QSelect
                                        label={t("Vai trò")}
                                        value={
                                            formik.values.role === undefined ? { label: roles[1].name, value: roles[1].id } : (
                                                QHelpers.object.mapSelectOption(
                                                    formik.values.role,
                                                    roles?.map((c: any) => ({ label: c.name, value: c.id }))
                                                )
                                            )
                                        }
                                        options={
                                            roles?.map((c: any) => ({ label: c.name, value: c.id }))
                                        }
                                        onChange={(option: SelectOptionInterface) => {
                                            formik.setFieldValue('role', option.value)
                                        }}
                                    />
                                </Col>
                                <Col>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <div className={'d-flex justify-content-end gap-2'}>
                                        <QButton
                                            type={'default'}
                                            onClick={() => { setOpen(0) }}
                                            content={t("common.button.cancel")}
                                        />
                                        <QButton
                                            type={'primary'}
                                            onClick={() => { formik.handleSubmit(); setLoading(true) }}
                                            content={t('common.button.submit')}
                                        />
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </React.Fragment>
                )}
            />

        </React.Fragment>
    )
}

export default BodyCustomerDetailComponent
