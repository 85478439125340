import { APIClient } from "../base";
import { PaginationInterface } from "../../../interfaces/PaginationInterface";
import { IntegrateInterface } from "../../../interfaces/private/IntegrateInterface";

const IntegrateApi = {
    list: async (pagination: PaginationInterface) => await APIClient.get('/integrate', pagination),
    detail: async (id: number) => await APIClient.get('/integrate/' + id, {}),
    getDetailByWooType: async () => await APIClient.get('/integrate/get-detail-by-woo-type', {}),
    updateByWooType: async (model: IntegrateInterface) => await APIClient.update('/integrate/update-by-woo-type', model),
    syncOneOrder: async (id: number) => await APIClient.create('/integrate/' + id + '/sync-one-integrate', {}),
    create: async (model: IntegrateInterface) => await APIClient.create('/integrate', model),
    update: async (id: number, model: IntegrateInterface) => await APIClient.update('/integrate/' + id, model),
    delete: async (id: number | string | any) => await APIClient.delete('/integrate/' + id, {}),
    cmsType: async () => await APIClient.get('/integrate/cms-type', {}),
    syncAllOrder: async () => await APIClient.create('/integrate/sync-all-integrates', {}),
    sendStock: async () => await APIClient.create('/integrate/send-stock', {}),
    updateStockToWoo: async () => await APIClient.create('/integrate/update-stock-to-woo', {}),
    syncProductToWoo: async () => await APIClient.create('/integrate/sync-product-from-web-shop', {}),
    tikiToken: async (clientId: string, clientSecret: string) => await APIClient.create('/integrate/tiki-token', {
        client_id: clientId,
        client_secret: clientSecret
    })
}

export default IntegrateApi;
