import {createSlice} from "@reduxjs/toolkit";
import {RootState} from "./store";
import {ToastInterface} from "../interfaces/ToastInterface";
import {GeneralConstant} from "../common/constant/GeneralConstant";

interface InitialInterface {
    toast: ToastInterface,
    user: {
        role: 'super_admin'|'user'|string,
    }
}

const initial: InitialInterface = {
    toast: {
        message: '',
        position: 'top-right',
        autoClose: 3000,
        closeOnClick: true,
        type: ''
    },
    user: {
        role: GeneralConstant.user.role.user
    }
}

export const RootReducerSlice = createSlice({
    name: 'RootReducerSlice',
    initialState: initial,
    reducers: {
        setToast: (state, action) => {
            state.toast.message = action.payload.message;
            state.toast.type = action.payload.type;
        },
        setUserRole: (state, action) => {
            state.user.role = action.payload;
        }
    }
})


// export const selectorRootReducer = (state: RootState) => state.

export const  {
    setToast,
    setUserRole,

} = RootReducerSlice.actions;
export default RootReducerSlice.reducer
