import { config } from "../../config";
import { sha256, sha224 } from 'js-sha256';
const generateUrlEconomic = (redirectUri: string = "") => {
    return `https://secure.e-conomic.com/secure/api1/requestaccess.aspx?appPublicToken=${config.INTEGRATION.ECONOMIC.PUBLIC_TOKEN}&redirectUrl=${redirectUri ? redirectUri : config.INTEGRATION.ECONOMIC.REDIRECT_URL}`;
}
const generateUrlLazada = (redirectUri: string = "", clientId: string) => {
    return `https://auth.lazada.com/oauth/authorize?response_type=code&force_auth=true&redirect_uri=${redirectUri}&client_id=${clientId}`;
}
const generateUrlShopee = (redirectUri: string = "", partnerId: string, Key: string) => {
    
    let time = Date.now();
    let timeSecond = JSON.stringify(time);
    let strTime = Number(timeSecond.substring(0, timeSecond.length - 3)) ;
    let base_string = partnerId + config.INTEGRATION.SHOPEE.PATH + JSON.stringify(strTime);
    let sign = sha256.hmac(Key, base_string);
    return `https://partner.shopeemobile.com/api/v2/shop/auth_partner?partner_id=${partnerId}&redirect=${redirectUri}&timestamp=${JSON.stringify(strTime)}&sign=${sign}`;
}
export const __string = {
    generateUrlEconomic,
    generateUrlLazada,
    generateUrlShopee
}