import React from "react";
import {PaginationInterface} from "../../../interfaces/PaginationInterface";
import {useTranslation} from "react-i18next";

interface Props {
    pagination: PaginationInterface,
    setPagination: Function,
    setLoading?: Function,
}

const QPagination: React.FC<Props> = ({
    pagination,
    setPagination,
    setLoading
}) => {
    const { t } = useTranslation();
    const totalPage = Math.ceil(pagination.total / (pagination.per_page > 0 ? pagination.per_page : 1));
    const renderPage = () => {
        let arrPage: any = [];
        for (let i = 0; i < totalPage; i++){
            arrPage.push(
                <li className={`page-item cursor-pointer ${ pagination.page === (i + 1) ? 'active' : '' }`} key={i}>
                    <a
                        className="page-link"
                        onClick={ async () => {
                            await setPagination({
                                ...pagination,
                                page: i+1,
                            })

                            if(setLoading){
                                await setLoading(true)
                            }

                        }}
                    >{ i+1 }</a>
                </li>
            )
        }

        return arrPage;
    }

    return (
        <React.Fragment>
            <div className="align-items-center mt-2 row g-3 text-center text-sm-start">
                <div className="col-5 text-left">
                    <div className="text-muted fs-12" style={{ display: 'flex' }}>
                        <div>
                            {t("common.paging.showingEntries", {
                                "from": (pagination.page - 1) * pagination.per_page + 1,
                                to: (pagination.per_page * pagination.page) > pagination.total ? pagination.total : (pagination.per_page * pagination.page),
                                total: pagination.total
                            })}
                            {/*Showing <span className="fw-semibold">*/}
                            {/*{ (pagination.page - 1) * pagination.per_page + 1 }*/}
                            {/*    <span> to </span>*/}
                            {/*    { (pagination.per_page * pagination.page) > pagination.total ? pagination.total : (pagination.per_page * pagination.page) }*/}
                            {/*</span> of <span className="fw-semibold">*/}
                            {/* { pagination.total } </span>*/}
                            {/*    entries*/}
                        </div>
                    </div>
                </div>
                {/*<div className="col-2 text-center text-muted fs-12 d-flex ">*/}
                {/*    <span className="mr-1 mt-2">Show</span>*/}
                {/*    <div className="entries">*/}
                {/*        <select*/}
                {/*            className="form-select form-select-zp text-muted fs-12 "*/}
                {/*            onChange={ async (event: any) => {*/}
                {/*                await setPagination({*/}
                {/*                    ...pagination,*/}
                {/*                    per_page: event.target.value*/}
                {/*                })*/}

                {/*                if(setLoading) setLoading(true);*/}
                {/*            }}*/}
                {/*            defaultValue={pagination.per_page}*/}
                {/*        >*/}
                {/*            <option value={20}>20</option>*/}
                {/*            <option value={50}>50</option>*/}
                {/*            <option value={100}>100</option>*/}
                {/*        </select>*/}
                {/*    </div>*/}
                {/*     <span className=" mt-2">entries</span>*/}
                {/*</div>*/}
                <div className="col-7 ">
                    <ul className="pagination pagination-separated pagination-sm justify-content-center justify-content-sm-start mb-0 float-end">
                        <li
                            className={`page-item ${pagination.page > 1 ? 'cursor-pointer' : 'disabled'} `}
                            onClick={ async () => {
                                if(pagination.page > 1){
                                    await setPagination({
                                        ...pagination,
                                        page: pagination.page - 1,
                                    })

                                    if(setLoading){
                                        await setLoading(true)
                                    }
                                }
                            }}
                        >
                            <a className="page-link"><i className="arrow-left"></i></a>
                        </li>
                        { renderPage() }
                        <li
                            className={`page-item ${pagination.page < totalPage ? 'cursor-pointer' : 'disabled'} `}
                            onClick={ async () => {
                                if(pagination.page < totalPage){
                                    await setPagination({
                                        ...pagination,
                                        page: pagination.page+1,
                                    })

                                    if(setLoading){
                                        await setLoading(true)
                                    }
                                }
                            }}
                        >
                            <a className="page-link"><i className="arrow-right"></i></a>
                        </li>
                    </ul>
                </div>
            </div>
        </React.Fragment>
    )
}

export default React.memo(QPagination);
