import { APIClient } from "../base";
import { PaginationInterface } from "../../../interfaces/PaginationInterface";

const UserApi = {
    register: async (model: any) => await APIClient.create('/register', model),
    ResetPassword: async (model: { password_confirm: string, password: string, email: string, sign: string, expire: string }) => await APIClient.create('/reset-password', model),
    ForgotPassword: async (model: {  email: string}) => await APIClient.create('/forgot-password', model),
    ChangePassword: async (model: {  current_password: string, new_password: string, new_password_confirm: string}) => await APIClient.create('user/change-password', model),
    getAll: async (pagination: PaginationInterface) => await APIClient.get('/user/list-user-by-admin', pagination),
    updateByAdmin: async (id: number | string | any, model: object | any) => await APIClient.update('/user/update-user-by-admin/' + id, model),
    createByAdmin: async (model: object | any) => await APIClient.create('/user', model),
    delete: async (id: number | string | any) => await APIClient.delete('/user/' + id, {}),
}

export default UserApi;
