import React, { useEffect, useState } from "react";
import { ResponseInterface } from "../../../interfaces/ResponseInterface";
import QLoading from "../../../common/components/QLoading";
import { UseSecondLayout } from "../../../context/LayoutContext";
import QModal from "../../../common/components/QModal";
import { config } from "../../../config";
import BodyEconomicComponent from "./components/BodyEconomicComponent";
import { IntegrateInterface, structIntegration } from "../../../interfaces/private/IntegrateInterface";
import IntegrateApi from "../../../common/api/functions/Integrate";
import SyncIntegrateComponent from "../Integrate/components/SyncIntegrateComponent";
import { useTranslation } from "react-i18next";
import SyncFromWooComponent from "../Integrate/components/SyncFromWooComponent";
import UpdateStockToWooComponent from "../Integrate/components/UpdateStockToWooComponent";
import SyncProductToWoo from "../Integrate/components/SyncProductToWoo";

const WooCommerce = () => {
    const [setup, setSetup] = useState<IntegrateInterface>(structIntegration);
    const [loading, setLoading] = useState(true);
    const [createNewClick, setCreateNewClick] = useState(false);
    const [syncFromWooToWebClick, setSyncFromWooToWebClick] = useState(false);
    const [syncUpdateStockToWooClick, setSyncUpdateStockToWooClick] = useState(false);
    const [syncProductToWoo, setSyncProductToWoo] = useState(false);
    const [dataLog, setDataLog] = useState('');
    const [openLog, setOpenLog] = useState(false);
    const [integrate, setIntegrate] = useState<IntegrateInterface | any>({ ...structIntegration });

    const { t } = useTranslation();

    UseSecondLayout();

    useEffect(() => {
        if (loading) {
            load().then(async () => {
                await setLoading(false);
            });
        }
    }, [loading]);

    const load = async () => {
        try {
            await IntegrateApi.getDetailByWooType().then(async (response: ResponseInterface | any) => {
                if (response?.status === config.STATUS_CODE.OK) {
                    console.log(response?.data?.data || {});
                    setSetup(response?.data?.data || {});
                }
            });
        } catch (err) { }

        if (loading) {
            await setLoading(false);
        }
    };

    return (
        <React.Fragment>
            <QModal
                open={createNewClick}
                setOpen={setCreateNewClick}
                headerContent={"Economic"}
                bodyContent={
                    <BodyEconomicComponent
                        model={setup}
                        setOpen={setCreateNewClick}
                        setSetup={setSetup}
                        setLoadingFunc={setLoading}
                    />
                }
            />

            <QModal
                open={syncFromWooToWebClick}
                setOpen={setSyncFromWooToWebClick}
                headerContent={t("integration.sync.title")}
                bodyContent={(
                    <SyncFromWooComponent
                        integrate={integrate}
                        model={syncFromWooToWebClick}
                        setOpen={async () => { await setSyncFromWooToWebClick(false) }}
                        setLoadingFunc={setLoading}
                        SETdATALOG={(data: any) => {
                            setDataLog(data);
                            setOpenLog(true);
                        }
                        }
                        onFinishSync={load}
                    />
                )}
            />

            <QModal
                open={syncUpdateStockToWooClick}
                setOpen={setSyncUpdateStockToWooClick}
                headerContent={t("integration.sync.title")}
                bodyContent={(
                    <UpdateStockToWooComponent
                        integrate={integrate}
                        model={syncUpdateStockToWooClick}
                        setOpen={async () => { await setSyncUpdateStockToWooClick(false) }}
                        setLoadingFunc={setLoading}
                        SETdATALOG={(data: any) => {
                            setDataLog(data);
                            setOpenLog(true);
                        }
                        }
                        onFinishSync={load}
                    />
                )}
            />

            <QModal
                open={syncProductToWoo}
                setOpen={setSyncProductToWoo}
                headerContent={t("integration.sync.title")}
                bodyContent={(
                    <SyncProductToWoo
                        integrate={integrate}
                        model={syncProductToWoo}
                        setOpen={async () => { await setSyncProductToWoo(false) }}
                        setLoadingFunc={setLoading}
                        SETdATALOG={(data: any) => {
                            setDataLog(data);
                            setOpenLog(true);
                        }
                        }
                        onFinishSync={load}
                    />
                )}
            />
            <div className="col-md-10 relative">
                <QLoading loading={loading} />
                <div className="row ">
                    <div className="row">
                        <div className="col-12">
                            <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                <div className="page-title-name">WooCommerce</div>
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <div className="business-detail bg-orange-bland-zp bdr-20 pd-25">
                                <div className="row">
                                    <div className="col-md-8">
                                        <h2 className="fw-bold fs-24 mb-2">WooCommerce Info</h2>
                                    </div>
                                    <div className="col-md-4 text-end">
                                        <a
                                            className={""}
                                            href="#"
                                            data-bs-toggle="modal"
                                            data-bs-target="#showModalBusinessDetail"
                                        >
                                            <i
                                                className="mr-2 las la-sync-alt fs-24 cl-br-ams"
                                                onClick={() => {
                                                    setSyncFromWooToWebClick(true);
                                                    setIntegrate(setup);
                                                }}
                                            />
                                        </a>
                                        <a
                                            className={""}
                                            href="#"
                                            data-bs-toggle="modal"
                                            data-bs-target="#showModalBusinessDetail"
                                        >
                                            <i
                                                className="mr-2 las la-arrow-down fs-24 cl-br-ams"

                                                onClick={() => {
                                                    setSyncUpdateStockToWooClick(true);
                                                    setIntegrate(setup);
                                                }}
                                            />
                                        </a>
                                        <a
                                            className={""}
                                            href="#"
                                            data-bs-toggle="modal"
                                            data-bs-target="#showModalBusinessDetail"
                                        >
                                            <i
                                                className="mr-2 las la-cloud-download-alt fs-24 cl-br-ams"

                                                onClick={() => {
                                                    setSyncProductToWoo(true);
                                                    setIntegrate(setup);
                                                }}
                                            />
                                        </a>
                                        <a
                                            className={""}
                                            href="#"
                                            data-bs-toggle="modal"
                                            data-bs-target="#showModalBusinessDetail"
                                        >
                                            <i
                                                className="las la-pen fs-24 cl-br-ams ml-2"
                                                onClick={() => {
                                                    setCreateNewClick(true);
                                                }}
                                            />
                                        </a>
                                    </div>
                                </div>
                                <div className="row">
                                    <b className="fw-bold">Host:</b><p className="mb-1">{setup.host}</p>
                                </div>
                                <div className="row">
                                    <b className="fw-bold">Consumer Key:</b><p className="mb-1">{setup.key}</p>
                                </div>
                                <div className="row">
                                    <b className="fw-bold">Consumer Secret:</b><p className="mb-1">{setup.secret}</p>
                                </div>
                            </div>
                            <br />
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default WooCommerce;
