import React, { useEffect, useState } from "react";
import { ResponseInterface } from "../../../interfaces/ResponseInterface";
import OrderApi from "../../../common/api/functions/Order";
import { OrderInterface, OrderLineInterface } from "../../../interfaces/private/OrderInterface";
import { useParams } from "react-router-dom";
import ShipmentNoComponent from "./components/ShipmentNoComponent";
import { UseSecond01Layout } from "../../../context/LayoutContext";
import QLoading from "../../../common/components/QLoading";
import Moment from "react-moment";
import moment from "moment";
import { config } from "../../../config";
import { Row } from "reactstrap";
import Log from "./components/Log";
import OrderDelivery from "./components/OrderDelivery";
import { useTranslation } from "react-i18next";

export function OrderDetail() {
    const { t } = useTranslation();
    const { id }: any = useParams();
    const [order, setOrder] = useState<OrderInterface | any>({});
    const [loading, setLoading] = useState(true);
    const [companyName, setCompanyName] = useState('');
    const [componentShippingAddressClick, setComponentShippingAddressClick] = useState<any>(0);
    const arrCompanyShip = [
        {
            key: 1,
            value: 'Giao Hàng Nhanh'
        },
        {
            key: 7,
            value: 'Giao Hàng Tiết Kiệm'
        },
        {
            key: 10,
            value: 'Viettel Post'
        }
    ];

    UseSecond01Layout();
    useEffect(() => {
        if (id && loading) {
            loadOrderDetail().then(async () => {
                await setLoading(false);
            })
        }
    }, [id])

    const loadOrderDetail = async () => {
        try {
            let response: ResponseInterface | any = await OrderApi.getDetail(id)
            if (response?.status === config.STATUS_CODE.OK) {
                await setOrder(response?.data?.data || {});
                await setCompanyName(getNameCompanyShip(response?.data?.data.shipment_id));

            }
        } catch (err) {
        }
        await setLoading(false);

    }

    const getNameCompanyShip = (CompanyId: number): any => {
        let data = arrCompanyShip.filter((index) => {
            return index.key == CompanyId;
        })
        return data[0].value;
    }

    return (
        <React.Fragment>
            <QLoading loading={loading} />
            <OrderDelivery
                order={order}
                open={componentShippingAddressClick}
                setOpen={() => {
                    setComponentShippingAddressClick(0)
                }}
                setLoading={setLoading}
                isMulti={false}
                onFinishUpdate={loadOrderDetail}
            />
            <Row className={'px-3'}>
                <div className="col-lg-8">
                    <div className="row">
                        <div className="col-md-6 border-right-ams">
                            <div className="row">
                                <div className="col-5">
                                    <h2 className="fs-24 fw-normal cl-ams mb-3">Đơn hàng #{order.orderNumber}</h2>
                                </div>
                            </div>

                            <div className="customer-order border-bottom-ams position-relative">
                                <p className="cl-bdd mb-1">Mã khách hàng: {order.customer_customerNumber}</p>
                                <h3 className="fs-24">{order.recipient_name}</h3>
                                <ul className="list-style-none lh-25 pl-0">
                                    <li>
                                        {order.attention ? <span>{order.attention.name}</span> : null} <br />
                                        {order.recipient_address ? <span>{order.recipient_address + ', '}</span> : null}
                                        {order.recipient_ward ? <span> P.{order.recipient_ward + ', '}</span> : null}
                                        {order.recipient_district ? <span>Q/TP.{order.recipient_district + ', '}</span> : null}
                                        <span>{order.recipient_city} </span>
                                        <span>{order.recipient_zip} </span>
                                        <span>{order.recipient_country}</span> <br />
                                        <span>{order?.customer?.email}</span> <br />
                                        <span>{order?.customer?.mobilePhone}</span> <br />


                                    </li>
                                </ul>
                            </div>
                            <div className="shipping-address mt-3 position-relative">
                                <div className="row">
                                    <div className="col-md-8">
                                        <p className="fw-bold">Địa chỉ nhận hàng</p>
                                    </div>
                                    <div className="col-md-4 text-end">
                                        <a onClick={() => {
                                            setComponentShippingAddressClick(true);
                                        }}>
                                            <i className="las la-pen fs-16 cl-br-ams ml-2" />
                                        </a>
                                    </div>
                                </div>
                                <ul className="list-style-none lh-25 pl-0">
                                    <li>
                                        <span>{order.delivery_address + ', '} </span>
                                        P.<span>{order.delivery_ward + ', '} </span>
                                        Q/TP.<span>{order.delivery_district + ', '} </span>
                                        <span>{order.delivery_city} </span>
                                        <span>{order.delivery_zip} </span>
                                        <span>{order.delivery_country}</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="pl-10">
                                <div className="advanced-options position-relative border-bottom-ams">
                                    <h3 className="fs-24 mb-3">Tùy chọn nâng cao</h3>
                                    <div className="row">
                                        <div className="col-6">
                                            <div className="mb-3">
                                                <p className="fw-bold mb-1">Phương thức thanh toán</p>
                                                <span>{order.paymentTerms_name ?? 'n/a'}</span>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className="mb-3">
                                                <p className="fw-bold mb-1">Ngày</p>
                                                <span>{order.date ? (<Moment date={order.date} format="DD.MM.YY" />) : 'n/a'}</span>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className="mb-3">
                                                <p className="fw-bold mb-1">Ngày giao hàng</p>
                                                <span>{moment(order.delivery_deliveryDate).isValid() ? moment(order.delivery_deliveryDate).format('DD.MM.YY') : 'n/a'}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="notes-order mt-3">
                                    <h3 className="fs-24 mb-3">Ghi chú về đơn hàng của bạn</h3>
                                    <div
                                        style={{ background: "white", cursor: "default", minHeight: 100 }}
                                        className="form-control d-block"
                                        placeholder=""
                                        spellCheck="false"
                                    >
                                        {order?.notes_heading?.trim() ? (
                                            <React.Fragment>
                                                <span>{order?.notes_heading?.trim()}</span><br />
                                            </React.Fragment>
                                        ) : null}

                                        <span>{order?.notes_textLine1}</span><br />
                                        {/*<span>{order?.notes_textLine2}</span>*/}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12 mt-4">
                            <div className="position-relative border-bottom-ams">
                                <p className="fw-bold fs-24">Sản phẩm</p>
                            </div>
                            <div className="live-preview">
                                <div className="table-responsive" style={{ maxHeight: 400 }}>
                                    <table className="table align-middle table-nowrap mb-0 table-none-pd-left">
                                        <thead>
                                            <tr>
                                                <th scope="col">Mã Sản phẩm</th>
                                                <th scope="col" style={{ width: '30%' }}>Tên Sản phẩm</th>
                                                <th scope="col">Giá</th>
                                                <th scope="col" className="text-center">Số lượng</th>
                                                <th scope="col" className="text-center">Cân nặng</th>
                                                <th scope="col" className="text-center">Ưu Đãi (%)</th>
                                                <th scope="col">Tổng</th>
                                                <th scope="col">Thuế</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {order?.lines?.map((line: OrderLineInterface, index: number) => (
                                                <tr key={index}>
                                                    <td>{line.sku}</td>
                                                    <td>{line.name}</td>
                                                    <td>{line?.product?.costPrice}</td>
                                                    <td className="text-center">{line.quantity}</td>
                                                    <td className="text-center">{line.weight}</td>
                                                    <td className="text-center">{line.discountPercentage}</td>
                                                    <td>{line.subtotal}</td>
                                                    <td>{line.total_tax}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/*<div className="row mt-4">
                        <div className="col-md-12">
                            <div className="event-order mt-3">
                                <h3 className="fs-24 mb-3">Logs</h3>
                                <input className="form-control input-sm d-block" placeholder="Add comment..."
                                       type="text" name="add_comment" value=""/>
                                <div className="timeline-2 timeline-ct-2 mt-3">
                                    <div className="timeline-continue">
                                        <div className="d-flex timeline-right mb-5">
                                            <div className="w-140">
                                                58 minutes ago
                                            </div>
                                            <div className="w-auto position-relative">
                                                <p className="timeline-date">
                                                    Billing information was changed from Casa Möbel, Schweizer
                                                    Straße 77, 6845, Hohenems, DK
                                                </p>
                                            </div>
                                        </div>
                                        <div className="d-flex timeline-right mb-5">
                                            <div className="w-140">
                                                58 minutes ago
                                            </div>
                                            <div className="w-auto position-relative">
                                                <p className="timeline-date">
                                                    Billing information was changed from Casa Möbel, Schweizer
                                                </p>
                                            </div>
                                        </div>
                                        <div className="d-flex timeline-right mb-5">
                                            <div className="w-140">
                                                58 minutes ago
                                            </div>
                                            <div className="w-auto position-relative">
                                                <p className="timeline-date">
                                                    Billing information was changed from Casa Möbel, Schweizer
                                                    Straße 77, 6845, Hohenems, DK
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>*/}
                </div>
                <div className="col-lg-4">
                    <div className=" h-100 bg-light-brown-zp p-3">
                        <div className="order-channel bg-border-pd mb-3"><p className="fw-bold fs-20 mb-1">Trạng thái</p>
                            <div className={((s: number) => {
                                s = s ?? 1;
                                return "btn bdr-4 mt-2 w-100 fw-500 status-" + (s == 1 ? 'open' : (s == 2 ? 'on-hold' : 'shipped'));
                            })(order.status)}>{t(`order.status.${order?.status_name?.toLowerCase()}`)}</div>
                        </div>
                        {order.shipment_id ? (
                            <ShipmentNoComponent orderId={order.id} companyShipId={order.shipment_id} label={order.label} trackAndTrace={order.traceandtrace} />
                        ) : null}
                        <div className="order-confirm position-relative bg-border-pd mb-3">
                            <p className="fw-bold fs-20 mb-3">Phương thức vận chuyển</p>
                            <p className="fw-bold mb-1">Đơn vị giao hàng</p>
                            <ul className="list-style-none lh-25 pl-0">
                                {
                                    <li>{companyName}</li>
                                }
                            </ul>
                            <p className="fw-bold mb-1">Dịch vụ giao hàng </p>
                            <ul className="list-style-none lh-25 pl-0">
                                <li>{order.shipment_service || "n/a"}</li>

                                {
                                    order?.shipment_service == "Shop Delivery Service" ?
                                        <li> Shop id:  {order?.shop_id}</li>
                                        : null
                                }
                                {
                                    order?.shipment_service == "Deposit Service" ?
                                        <li> Deposit Message:  {order?.deposit_message}</li>
                                        : null
                                }

                            </ul>
                            <button className="btn text-end btn-end-top d-none" data-bs-toggle="modal"
                                data-bs-target="#showModalChangeShipment"><i
                                    className="las la-pen fs-16 cl-br-ams"></i></button>
                        </div>
                        {/*<div className="order-channel bg-border-pd mb-3">
                            <p className="fw-bold fs-16 mb-1">Channels</p>
                            <button className="btn mt-2 bg-orange-bland-zp w-100 text-center bdr-4">Woocommerce shop
                            </button>
                        </div>*/}
                        {/* <div className="order-payment mb-3 position-relative bg-border-pd">
                            <p className="fw-bold fs-16 mb-1">Payment information</p>
                            <div className="mt-4 pb-3">
                                <div className="row">
                                    <div className="col-6">Subtotal</div>
                                    <div className="col-6 text-end">{order.currency} {order.netAmount}</div>
                                    <div className="col-6">Delivery</div>
                                    <div className="col-6 text-end">DKK 100,00</div>
                                    <div className="col-6">VAT amount</div>
                                    <div
                                        className="col-6 text-end">{order.currency} {order.grossAmount - order.netAmount}</div>
                                </div>
                            </div>
                            <div className="row mt-2">
                                <div className="col-4"><span className="fw-bold lh-30">Total incl.Vat</span></div>
                                <div className="col-8 text-end"><span
                                    className="fs-20">{order.currency} {order.grossAmount}</span></div>
                            </div>
                            <button className="btn text-end btn-end-top d-none" data-bs-toggle="modal"
                                    data-bs-target="#showModalEditPayment"><i
                                className="las la-pen fs-16 cl-br-ams"></i></button>
                        </div> */}

                        <Log orderId={id} />
                    </div>
                </div>
                <div className="col-8">
                    <div className=" h-100 bg-light-brown-zp text-end">
                        <div className="order-confirm position-relative bg-border-pd row">
                            <div className="col-5"></div>
                            <div className="col-5">
                                <div className="text-end">
                                    <p className="mb-1">Tổng tiền sản phẩm:</p>
                                    <p className="mb-1">Phiếu giảm giá:</p>
                                    <p className="mb-1">Thuế:</p>
                                    <p className="mb-1">Tổng đơn:</p>
                                </div>
                            </div>
                            <div className="col-2">
                                <div className="text-end">
                                    <p className="fw-bold mb-1">{order.netAmount} {order?.user?.currency}</p>
                                    <p className="fw-bold mb-1">{order?.discount_total == 0?order?.discount_total: -order?.discount_total } {order?.user?.currency}</p>
                                    <p className="fw-bold mb-1">{order?.total_tax} {order?.user?.currency}</p>
                                    <p className="fw-bold mb-1"> {order.grossAmount}  {order?.user?.currency}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Row>
        </React.Fragment>
    )
}

export default OrderDetail;
